import React, { Component } from "react";
import Loader from "react-loader-spinner";
import {
  Modal,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";

const descLimit = 100;
const nameLimit = 40;

class UpdateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      descCharLeft: descLimit,
      nameCharLeft: nameLimit,
      pending: false,
    };
  }

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  updateName = (event) => {
    this.setState({
      nameCharLeft: nameLimit - event.target.value.length,
      name: event.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          size="md"
          isOpen={this.props.updateModal}
          centered={true}
          backdrop="static"
          wrapClassName="modal-balance"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Update Wallet information
            </h5>
          </div>
          <div className="modal-body">
            {this.props.error && (
              <p className="text-danger">{this.props.error}</p>
            )}

            <FormGroup>
              <Label>Set a wallet name</Label>

              <Row>
                <Col sm="12">
                  <InputGroup className="mb-2">
                    <InputGroupAddon addonType="prepend">
                      <span className="input-group-text">
                        {this.state.nameCharLeft}
                      </span>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      className="form-control"
                      maxLength={nameLimit}
                      value={
                        this.state.name ||
                        (this.props.selectedWallet &&
                          this.props.selectedWallet.name)
                      }
                      onChange={this.updateName}
                      placeholder={
                        this.props.selectedWallet &&
                        this.props.selectedWallet.name
                      }
                    />
                    <InputGroupAddon addonType="append">
                      {this.state.name && this.state.name.length > 3 ? (
                        <h4 className="ml-2 text-success">
                          <b className="bx bx-message-alt-check"></b>
                        </h4>
                      ) : (
                        <h4 className="ml-2 text-danger">
                          <b className="bx bx-message-alt-x"></b>
                        </h4>
                      )}
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Label>Set a short description</Label>

              <Row>
                <Col sm="12">
                  <InputGroup className="mb-2">
                    <InputGroupAddon addonType="prepend">
                      <span className="input-group-text">
                        {this.state.descCharLeft}
                      </span>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      className="form-control"
                      maxLength={descLimit}
                      value={
                        this.state.description ||
                        (this.props.selectedWallet &&
                          this.props.selectedWallet.description)
                      }
                      onChange={this.updateDescription}
                      placeholder={
                        this.props.selectedWallet &&
                        this.props.selectedWallet.description
                      }
                    />
                  </InputGroup>
                </Col>
              </Row>
            </FormGroup>
            {this.state.pending ? (
              <React.Fragment>
                <Loader type="Puff" color="#50a5f1" height={50} width={50} />
                <p className="mt-2 text-info">
                  Notification sent onto your TrustBuilder Authenticator
                  application.{" "}
                  <strong>Set your pin code to update wallet...</strong>
                </p>
              </React.Fragment>
            ) : (
              <Row className="mt-3">
                <Col sm="4">
                  <button
                    className="btn btn-dark btn-block waves-effect waves-light"
                    type="submit"
                    onClick={() => this.props.toggleUpdateModal()}
                  >
                    Cancel
                  </button>
                </Col>
                <Col sm="8">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                    onClick={() => {
                      this.setState({ pending: true });
                      this.props.updateWallet(
                        this.props.selectedWallet.publicKeyHash,
                        this.state.name ||
                          (this.props.selectedWallet &&
                            this.props.selectedWallet.name),
                        this.state.description ||
                          (this.props.selectedWallet &&
                            this.props.selectedWallet.description)
                      );
                    }}
                  >
                    Update wallet
                  </button>
                </Col>
              </Row>
            )}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default UpdateModal;
