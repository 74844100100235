import React, { Component } from 'react';

import { Row, Col, CardBody } from "reactstrap";

import { Line } from 'rc-progress';

class Complete extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <CardBody>
                <div>
                    <h5>
                        Registration successfully complete
                    </h5>
                    <Line percent={100} strokeWidth={2} strokeColor="#08EA8A" />
                    <Row className="mt-4">
                        <Col className="col-xl-1 col-md-2">
                            <h1 className="m-0">
                                <i className="mdi mdi-party-popper text-primary text-right"></i>
                            </h1>
                        </Col>
                        <Col className="col-10 align-self-end">
                            <p>
                                Your registration is now over, we will process it as soon as possible. You will be informed
                                by the email address you have registered for any additional request
                                or validation of your registration.
                            </p>
                            <p>
                                <strong>Thanks a lot for you trust. <a href="https://schuman.io" rel={"noreferrer"} target={"_blank"} className="text-success">Back to Schuman Financial.com</a></strong>
                            </p>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        );
    }
}

export default Complete;