import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import classnames from "classnames";
import Loader from "react-loader-spinner";
import { referenceWallet } from "../../helpers/api";

const descLimit = 100;
const nameLimit = 40;

class NewWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      description: null,
      validPhrase: false,
      descCharLeft: descLimit,
      nameCharLeft: nameLimit,
      pending: false,
      error: null,
      activeTab: "2",
      publicKeyHashTezos: null,
      publicKeyHashEthereum: null,
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  updateDescription = (event) => {
    this.setState({
      descCharLeft: descLimit - event.target.value.length,
      description: event.target.value,
    });
  };

  updateName = (event) => {
    this.setState({
      nameCharLeft: nameLimit - event.target.value.length,
      name: event.target.value,
    });
  };

  updateEthAddr = (event) => {
    this.setState({
      publicKeyHashEthereum: event.target.value,
      connectedEthLedger: event.target.value.length === 42,
    });
  };

  updateTzAddr = (event) => {
    this.setState({
      publicKeyHashTezos: event.target.value,
      connectedLedger: event.target.value.length === 36,
    });
  };

  referenceTezos = () => {
    if (
      !this.state.publicKeyHashTezos ||
      this.state.publicKeyHashTezos.length !== 36
    )
      this.setState({ error: "Address format ivalid", pending: false });
    else if (this.state.name.length < 4)
      this.setState({ error: "Invalid wallet name!", pending: false });
    else {
      this.setState({ pending: true, error: null });
      this._asyncRequest = referenceWallet(
        this.state.publicKeyHashTezos,
        this.state.name,
        this.state.description,
        "tezos"
      )
        .then((res) => {
          if (res.status === "SUCCESS") {
            window.location = "/wallets";
          } else {
            this.setState({ error: res.error, pending: false });
          }
        })
        .catch((error) => {
          this.setState({ error: error.toString(), pending: false });
        });
    }
  };

  referenceEthereum = () => {
    if (
      !this.state.publicKeyHashEthereum ||
      this.state.publicKeyHashEthereum.length !== 42
    )
      this.setState({ error: "Address format invalid", pending: false });
    else if (this.state.name.length < 4)
      this.setState({ error: "Invalid wallet name!", pending: false });
    else {
      this.setState({ pending: true, error: null });
      this._asyncRequest = referenceWallet(
        this.state.publicKeyHashEthereum,
        this.state.name,
        this.state.description,
        "ethereum"
      )
        .then((res) => {
          if (res.status === "SUCCESS") {
            window.location = "/wallets";
          } else {
            this.setState({ error: res.error, pending: false });
          }
        })
        .catch((error) => {
          this.setState({ error: error.toString(), pending: false });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">
                      <i className="bx bx-wallet"></i> Reference your wallet on
                      SCHUMAN Financial
                    </h4>
                    <div className="crypto-buy-sell-nav">
                      <Nav tabs className="nav-tabs-custom" role="tablist">
                        {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "1",
                            })}
                            onClick={() => {
                              this.toggleTab("1");
                            }}
                          >
                            <p className="font-weight-bold mb-1">Tezos</p>
                          </NavLink>
                        </NavItem> */}
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === "2",
                            })}
                            onClick={() => {
                              this.toggleTab("2");
                            }}
                          >
                            <p className="font-weight-bold mb-1">
                              Ethereum & Polygon
                            </p>
                          </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent
                        activeTab={this.state.activeTab}
                        className="crypto-buy-sell-nav-content p-4"
                      >
                        <TabPane tabId="1" id="tezos">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}
                          <FormGroup>
                            <Label>Wallet address</Label>

                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="tz1.."
                                    onChange={this.updateTzAddr}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Wallet name</Label>

                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      {this.state.nameCharLeft} char. left
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={nameLimit}
                                    onChange={this.updateName}
                                    autoComplete="off"
                                  />
                                  <InputGroupAddon addonType="append">
                                    {this.state.name &&
                                    this.state.name.length > 3 ? (
                                      <h4 className="ml-2 text-success">
                                        <b className="bx bx-message-alt-check"></b>
                                      </h4>
                                    ) : (
                                      <h4 className="ml-2 text-danger">
                                        <b className="bx bx-message-alt-x"></b>
                                      </h4>
                                    )}
                                  </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Set a short description</Label>

                            <Row>
                              <Col sm="12">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      {this.state.descCharLeft} char. left
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={descLimit}
                                    onChange={this.updateDescription}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <div className="mt-4">
                            {this.state.pending ? (
                              <React.Fragment>
                                <Loader
                                  type="Puff"
                                  color="#50a5f1"
                                  height={50}
                                  width={50}
                                />
                                <p className="mt-2 text-info">
                                  Notification sent onto your TrustBuilder
                                  Authenticator application.{" "}
                                  <strong>
                                    Set your pin code to save wallet...
                                  </strong>
                                </p>
                              </React.Fragment>
                            ) : (
                              <Button
                                type="button"
                                color="light"
                                onClick={this.referenceTezos}
                              >
                                Reference Tezos wallet
                              </Button>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tabId="2" id="tezos">
                          {this.state.error && (
                            <p className="text-danger">{this.state.error}</p>
                          )}
                          <FormGroup>
                            <Label>Wallet address</Label>

                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <Input
                                    type="text"
                                    className="form-control"
                                    placeholder="0x..."
                                    onChange={this.updateEthAddr}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Wallet name</Label>

                            <Row>
                              <Col sm="8">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      {this.state.nameCharLeft} char. left
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={nameLimit}
                                    onChange={this.updateName}
                                    autoComplete="off"
                                  />
                                  <InputGroupAddon addonType="append">
                                    {this.state.name &&
                                    this.state.name.length > 3 ? (
                                      <h4 className="ml-2 text-success">
                                        <b className="bx bx-message-alt-check"></b>
                                      </h4>
                                    ) : (
                                      <h4 className="ml-2 text-danger">
                                        <b className="bx bx-message-alt-x"></b>
                                      </h4>
                                    )}
                                  </InputGroupAddon>
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>

                          <FormGroup>
                            <Label>Set a short description</Label>

                            <Row>
                              <Col sm="12">
                                <InputGroup className="mb-2">
                                  <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                      {this.state.descCharLeft} char. left
                                    </span>
                                  </InputGroupAddon>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    maxLength={descLimit}
                                    onChange={this.updateDescription}
                                    autoComplete="off"
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                          <div className="mt-4">
                            {this.state.pending ? (
                              <React.Fragment>
                                <Loader
                                  type="Puff"
                                  color="#50a5f1"
                                  height={50}
                                  width={50}
                                />
                                <p className="mt-2 text-info">
                                  Notification sent onto your TrustBuilder
                                  Authenticator application.{" "}
                                  <strong>
                                    Set your pin code to save wallet...
                                  </strong>
                                </p>
                              </React.Fragment>
                            ) : (
                              <Button
                                type="button"
                                color="light"
                                onClick={this.referenceEthereum}
                              >
                                Reference Ethereum & Polygon wallet
                              </Button>
                            )}
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default NewWallet;
