import React, { Component } from 'react';
import { Row, Col, CardBody , Input} from "reactstrap";
import { Line } from 'rc-progress';

const validator = require("email-validator")

class CompanyInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {dropdownOpen: false,
                      companyName: null,
                      businessSector: null,
                      registrationNumber: null,
                      fullAddress: null,
                      legalForm: null,
                      purpose: null,
                      contactMail: null,
                      error: null,
                    }
    }

    toggleDropdown = () => {
        this.setState({dropdownOpen: !this.state.dropdownOpen});
    }

    validateCompany = () => {
        if((!this.state.companyName ||
            !this.state.businessSector ||
            !this.state.registrationNumber ||
            !this.state.fullAddress ||
            !this.state.legalForm ||
            !this.state.purpose) && !this.props.company.companyName){
                this.setState({error: 'Please fill all required information'})
        }else if(!validator.validate(this.state.contactMail) && !validator.validate(this.props.company.contactMail)){
            this.setState({error: 'Email format is incorrect'})
        }else{
            this.setState({error: null})
            this.props.submitCompany({
                companyName: this.state.companyName || this.props.company.companyName,
                businessSector: this.state.businessSector || this.props.company.businessSector,
                registrationNumber: this.state.registrationNumber || this.props.company.registrationNumber,
                fullAddress: this.state.fullAddress || this.props.company.fullAddress,
                legalForm: this.state.legalForm || this.props.company.legalForm,
                purpose: this.state.purpose || this.props.company.purpose,
                contactMail: this.state.contactMail || this.props.company.contactMail,
            })
        }
    }

    render() {
        return (
            <CardBody>
                <div>
                    <h5>
                        2. Company information
                    </h5>
                    <Line percent={20} strokeWidth={2} strokeColor="#2eecc7" />
                    <Row className="mt-4">
                        <Col className="col-xl-1 col-md-2">
                            <h1 className="m-0">
                                <i className="mdi mdi-domain text-primary text-right"></i>
                            </h1>
                        </Col>
                        <Col className="col-10 align-self-end">
                            <p>
                                Fill all required information regarding the identification of your company
                            </p>
                        </Col>
                    </Row>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Company name</strong> <span className="text-danger">*</span></p>
                        <Input name="company_name" className="form-control" placeholder="Company name" type="text" 
                               value={this.state.companyName || this.props.company.companyName}
                               onChange={(e) => this.setState({companyName: e.target.value})} required />
                    </div>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Business category</strong> <span className="text-danger">*</span></p>
                        <Input name="business_sector" className="form-control" placeholder="Trading platform" type="text" 
                               value={this.state.businessSector || this.props.company.businessSector}
                               onChange={(e) => this.setState({businessSector: e.target.value})} required />
                    </div>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Registration number</strong> <span className="text-danger">*</span></p>
                        <Input name="registration_number" className="form-control" placeholder="Registration number" type="text" 
                               value={this.state.registrationNumber || this.props.company.registrationNumber}
                               onChange={(e) => this.setState({registrationNumber: e.target.value})} required  />
                    </div>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Address of domiciliation and/or head office</strong> <span className="text-danger">*</span></p>
                        <Input name="address" className="form-control" placeholder="Full address (street, state, city, country)" type="text" 
                               value={this.state.fullAddress || this.props.company.fullAddress}
                               onChange={(e) => this.setState({fullAddress: e.target.value})} required  />
                    </div>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Legal form</strong> <span className="text-danger">*</span></p>
                        <Input name="legal" className="form-control" placeholder="Legal form" type="text" 
                               value={this.state.legalForm || this.props.company.legalForm}
                               onChange={(e) => this.setState({legalForm: e.target.value})} required  />
                    </div>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Purpose of the Business Relationship</strong> <span className="text-danger">*</span></p>
                        <Input name="relationship" className="form-control" placeholder="Tell us more about your needs" type="text" 
                               value={this.state.purpose || this.props.company.purpose}
                               onChange={(e) => this.setState({purpose: e.target.value})} required  />
                    </div>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Administration mail</strong> <small>(Email that will be used to create company administrative account and that will receive all the information & notifications)</small> <span className="text-danger">*</span></p>
                        <Input name="relationship" className="form-control" placeholder="Contact mail" type="email" 
                               value={this.state.contactMail || this.props.company.contactMail}
                               onChange={(e) => this.setState({contactMail: e.target.value})} required  />
                    </div>
                    <p>
                        <span className="text-danger">*</span> <small>All mandatory fields</small>
                    </p>
                    {this.state.error && <p className="my-1 text-danger">
                        <strong>{this.state.error}</strong>
                    </p>}
                    <Row className="mt-3">
                        <Col className="col-4">
                            <div className="btn btn-secondary btn-block waves-effect waves-light" onClick={() => this.props.handleChangeView('cgu')}>
                                {"<"} Back
                            </div>
                        </Col>
                        <Col className="col-8">
                            <div className="btn btn-primary btn-block waves-effect waves-light" onClick={this.validateCompany}>
                                Validate company info
                            </div>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        );
    }
}

export default CompanyInfo;

