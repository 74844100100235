import React, { Component } from 'react';
import Loader from 'react-loader-spinner';

import { clientProfile, updateWallet } from '../../helpers/api';
import { Container, Row, Col, Table, Button } from "reactstrap";
import Can from '../../components/Can';
import UpdateModal from "./UpdateModal.js";

import { connect } from 'react-redux';
import SVGLogo from '../../components/Common/SVGLogo';
import EthereumLogo from '../../components/Common/EthereumLogo';
import TezosLogo from '../../components/Common/TezosLogo';
import PolygonLogo from '../../components/Common/PolygonLogo';

class Wallets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            wallets: [],
            selectedWallet: null,
            updateModal: false,
            error: null,
        }
    }

    loadClient = async () => {
        this.setState({ loading: true })
        const res = await clientProfile()
        this.setState({
            client: res.data.client, wallets: res.data.wallets, loading: false
        })
    }

    componentDidMount = () => {
        this.loadClient()
    }

    toggleUpdateModal = (updWallet) => {
        if (updWallet) {
            this.setState({ selectedWallet: updWallet })
        }
        this.setState({ updateModal: !this.state.updateModal, error: null })
    }

    updtWallet = (publicKeyHash, name, description = null) => {
        this._asyncRequest = updateWallet(publicKeyHash, name, description).then(
            res => {
                if (res.status === 'SUCCESS') {
                    window.location.reload();
                } else {
                    this.setState({ error: res.error });
                    console.log(res.error);
                }
            }
        ).catch(error => {
            this.setState({ error: error.toString() });
            console.log(error);
        });
    }

    render() {
        return (
            <React.Fragment>
                <UpdateModal updateModal={this.state.updateModal}
                            toggleUpdateModal={this.toggleUpdateModal}
                            selectedWallet={this.state.selectedWallet}
                            error={this.state.error}
                            updateWallet={this.updtWallet}/>
                <div className="page-content">
                    <Container fluid>
                        {this.state.loading ?
                            <Loader type="Puff" color="#545454" height={50} width={50} />
                            :
                            <Row>
                                <Col lg="12">
                                    <div className="">
                                        <div className="table-responsive">
                                            <Table className="project-list-table table-nowrap table-centered table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style={{ width: "100px" }}>Chain</th>
                                                        <th scope="col">Wallet</th>
                                                        <th scope="col">Balance</th>
                                                        {/* <th scope="col">Operations</th> */}
                                                        <Can role={this.props.user.role} perform="client:admin" yes={() => (
                                                            <th scope="col">Update</th>
                                                        )} />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.wallets && this.state.wallets.map(wallet =>
                                                        <tr key={wallet.publicKeyHash}>
                                                            <td>
                                                                {wallet.chain === 'ethereum' ?
                                                                    <EthereumLogo width="30" height="30" />
                                                                    :
                                                                    wallet.chain === 'polygon' ?
                                                                    <PolygonLogo width="30" height="30" />
                                                                    :
                                                                    <TezosLogo width="30" height="30" />
                                                                }
                                                            </td>
                                                            <td>
                                                                <h5 className="text-truncate font-size-14">
                                                                    <span className="text-dark">
                                                                        <strong>{wallet.chain}</strong>: {wallet.name}
                                                                    </span>
                                                                </h5>
                                                                <p className="text-muted mb-0">{wallet.publicKeyHash}</p>
                                                                <p className="text-muted mb-0">{wallet.description}</p>
                                                            </td>
                                                            <td><strong><SVGLogo /> {wallet.balance && wallet.balance.toLocaleString()}</strong></td>
                                                            {/* <td>
                                                                <Link as="span" className="badge badge-primary font-size-11 clickable" to={`/address-tx/${wallet.publicKeyHash}`}>View</Link>
                                                            </td> */}
                                                            {wallet.walletType !== 'auto' ? <Can role={this.props.user.role} perform="client:admin" yes={() => (
                                                                <td>
                                                                    <button className="btn btn-lg mr-1" onClick={() => { this.toggleUpdateModal(wallet) }}>
                                                                        <i className="bx bx-wrench"></i>
                                                                    </button>
                                                                </td>
                                                            )} />
                                                                :
                                                                <td></td>
                                                            }
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                            <Can role={this.props.user.role} perform="client:admin" yes={() => (<Button className="mb-5" type="button" color="light" onClick={() => window.location.href='/new-wallet'}>Reference new Wallet</Button>)}/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user } = state.Login;
    return { user };
}

export default connect(mapStatetoProps, null)(Wallets);