import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Input, Label, Button, Nav, TabContent, TabPane, InputGroup, InputGroupAddon } from "reactstrap";
import { clientProfile, clientSaveRequest, feeClient, getBaseFee } from '../../helpers/api';
import { makeRefId } from '../../helpers/formatter';
import Loader from 'react-loader-spinner';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SVGLogo from '../../components/Common/SVGLogo';
import TezosLogo from '../../components/Common/TezosLogo';
import EthereumLogo from '../../components/Common/EthereumLogo';
import { truncStringPortion } from '../../helpers/formatter.js';

const IBAN = require('iban');
const descLimit = 100;

class Purchase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0,
            description: null,
            descCharLeft: descLimit,
            pending: false,
            error: null,
            activeTab: '1',
            publicKeyHash: null,
            copied: false,
            displayIban: false,
            country: null,
            idbanks: [],
            client: null,
            selectedIban: null,
            referenceId: null,
            bic: null,
            salvusIban: null,
            nbPendings: 0,
            clients: [],
            wallets: [],
            selectedClient: null,
            selectedWallet: null,
            feeModel: null,
            commission: 0,
            chain: 'ethereum',
            loading: false,
            baseFees: [],
            baseFee: 0,
        }
        this.toggleTab = this.toggleTab.bind(this);
    }

    toggleTab(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    updateDescription = (event) => {
        this.setState({ descCharLeft: descLimit - event.target.value.length, description: event.target.value });
    }

    updateAmount = (event) => {
        this.setState({ amount: parseInt(event.target.value) });
    }

    loadClient = async () => {
        this.setState({ loading: true })
        const res = await clientProfile()
        const feeModel = await feeClient();
        const baseFees = await getBaseFee()
        await this.setState({ feeModel: feeModel.data, baseFees: baseFees.data })
        this.updateChain(this.state.chain)
        this.setState({ client: res.data.client, selectedClient: res.data.client.publicKeyHash, wallets: res.data.wallets, idbanks: res.data.idbanks, salvusIban: res.data.salvusIban, loading: false })
    }

    componentDidMount = () => {
        this.loadClient()
    }


    validateRequest = () => {
        this.setState({ error: null })
        if (!this.state.selectedIban) {
            this.setState({ error: 'No IBAN selected or IBAN / BIC invalid' });
            return;
        } else if (this.state.amount < 100) {
            this.setState({ error: 'Invalid EUROP amount' });
            return;
        } else if (!this.state.selectedWallet) {
            this.setState({ error: 'No wallet selected, please add one in your account information' });
            return;
        } else {
            if (this.state.feeModel) {
                this.setState({ commission: Math.floor((this.state.feeModel.mint / 100) * this.state.amount) })
            }
            this.setState({ referenceId: makeRefId(8), amount: Math.floor(this.state.amount) });
            this.toggleTab("2");
        }
    }

    registerRequest = () => {
        this.setState({ pending: true });
        this._asyncRequest = clientSaveRequest(this.state.referenceId, this.state.amount, this.state.description, this.state.iban || this.state.selectedIban, 'PURCHASE', this.state.selectedWallet, this.state.chain).then(
            res => {
                if (res.status === 'SUCCESS') {
                    this.toggleTab("3");
                } else {
                    console.log(res.error);
                    this.setState({ error: res.error, pending: false });
                }
            }
        ).catch(error => {
            console.log(error);
            this.setState({ error: error.toString(), pending: false });
        });
    }

    copyReference = () => {
        navigator.clipboard.writeText(this.state.referenceId);
        this.setState({ copied: true });
        setTimeout(
            function () {
                this.setState({ copied: false });
            }
                .bind(this),
            2000
        );
    }

    updateChain = async (chain) => {
        await this.setState({ baseFee: 0 })
        for (let baseFee of this.state.baseFees) {
            if (baseFee.chain === chain)
                this.setState({ baseFee: baseFee.euro })
        }
        this.setState({ chain: chain })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumbs title="Request" breadcrumbItem="Purchase EUROP" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title mb-4">Set a purchase request to buy EUROP</h4>
                                        <div className="crypto-buy-sell-nav">
                                            <Nav tabs className="nav-tabs-custom" role="tablist">
                                            </Nav>

                                            <TabContent activeTab={this.state.activeTab} className="crypto-buy-sell-nav-content p-4">
                                                <TabPane tabId="1" id="create">
                                                    <p>Please fill all required fields</p>
                                                    {this.state.error && <p className="text-danger font-weight-bold">{this.state.error}</p>}

                                                    {this.state.loading ?
                                                        <Loader type="Puff" color="#545454" height={50} width={50} />
                                                        : this.state.selectedClient &&
                                                        <React.Fragment>
                                                            <FormGroup>
                                                                <Label>1. Select chain</Label>
                                                                <Row>
                                                                    <Col xs="12">
                                                                        <p>
                                                                            {/* <span className="mr-2">
                                                                                <input type="radio" name="chain" onClick={() => { this.updateChain('tezos') }} defaultChecked={true} /> Tezos <small>[chain fee: <strong>{this.state.baseFees.map(baseFee => baseFee.chain === 'tezos' && baseFee.euro * 2)}€</strong>]</small>
                                                                            </span> */}
                                                                            <span className="mr-2">
                                                                                <input type="radio" name="chain" onClick={() => { this.updateChain('ethereum') }} /> Ethereum <small>[chain fee: <strong>{this.state.baseFees.map(baseFee => baseFee.chain === 'ethereum' && baseFee.euro * 2)}€</strong>]</small>
                                                                            </span>
                                                                            <span className="mr-2">
                                                                                <input type="radio" name="chain" onClick={() => { this.updateChain('polygon') }} /> Polygon <small>[chain fee: <strong>{this.state.baseFees.map(baseFee => baseFee.chain === 'polygon' && baseFee.euro * 2)}€</strong>]</small>
                                                                            </span>
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>

                                                            {this.state.wallets && this.state.wallets.length > 0 && <FormGroup>
                                                                <Label>2. Select receiving wallet</Label>
                                                                <Row>
                                                                    {this.state.wallets.map(wallet => wallet.chain === this.state.chain &&
                                                                        <Col xl="3" sm="12" key={wallet.publicKeyHash}>
                                                                            <div className="mb-3">
                                                                                <label className="card-radio-label mb-2" onClick={() => { this.setState({ selectedWallet: wallet.publicKeyHash }) }}>
                                                                                    <input type="radio" name="wallet" id={wallet.publicKeyHash} className="card-radio-input" readOnly />

                                                                                    <div className="card-radio">
                                                                                        <div>
                                                                                            {wallet.chain === 'tezos' ?
                                                                                                <TezosLogo width="18" height="18" />
                                                                                                :
                                                                                                <EthereumLogo width="18" height="18" />
                                                                                            }
                                                                                            <span>{truncStringPortion(wallet.publicKeyHash, 8, 6)}</span>
                                                                                        </div>
                                                                                        <div>
                                                                                            <p className="text-muted font-size-11 mb-1">{wallet.name}</p>
                                                                                            <h5 className="font-size-16 mb-1">{wallet.balance && wallet.balance.toLocaleString()} <SVGLogo width="18" height="18" /></h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </FormGroup>}

                                                            <FormGroup>
                                                                <Label>3. EUROP amount to purchase</Label>

                                                                <Row>
                                                                    <Col sm="8">
                                                                        <InputGroup className="mb-2">
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <span className="input-group-text">EUROP amount</span>
                                                                            </InputGroupAddon>
                                                                            <Input type="number" className="form-control" onChange={this.updateAmount}
                                                                                min="1" step="1" autoComplete="off" placeholder="Whole amount" />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>

                                                            <FormGroup>
                                                                <Label>4. Short description <small>(optional)</small></Label>

                                                                <Row>
                                                                    <Col sm="12">
                                                                        <InputGroup className="mb-2">
                                                                            <InputGroupAddon addonType="prepend">
                                                                                <span className="input-group-text">{this.state.descCharLeft} char. left</span>
                                                                            </InputGroupAddon>
                                                                            <Input type="text" className="form-control" maxLength={descLimit} onChange={this.updateDescription} autoComplete="off" />
                                                                        </InputGroup>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>

                                                            <FormGroup>
                                                                {this.state.idbanks && this.state.idbanks.length > 0 &&
                                                                    <Label>5. Click to select a registered Bank account from which wire tranfer will be sent</Label>
                                                                }
                                                                <Row>
                                                                    {this.state.idbanks && this.state.idbanks.map(idbank =>
                                                                        <Col xl="3" sm="12" key={idbank.iban}>
                                                                            <div className="mb-3">
                                                                                <label className="card-radio-label mb-2" onClick={() => { this.setState({ selectedIban: idbank.iban }) }}>
                                                                                    <input type="radio" name="iban" id={idbank.iban} className="card-radio-input" readOnly />

                                                                                    <div className="card-radio">
                                                                                        <div>
                                                                                            <p className="font-size-12 mb-1">{idbank.bank || `${this.state.client.name} ${idbank.bic.substring(0, 5)}`}</p>
                                                                                            <p className="text-muted font-size-12 mb-1">{idbank.iban}</p>
                                                                                            <p>{idbank.bic}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </Col>
                                                                    )}
                                                                </Row>
                                                            </FormGroup>
                                                            <div className="mt-4">
                                                                <Button type="button" color="primary" onClick={this.validateRequest}>Next</Button>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </TabPane>
                                                <TabPane tabId="2" id="validation">
                                                    <h5 className="mb-4">Validate purchase request</h5>
                                                    <p>To receive EUROP please validate following request and wire transfer € funds to Schuman Financial with following Reference ID</p>
                                                    <hr />
                                                    <h5 className="mb-3">
                                                        Reference ID : <strong className="text-primary">{this.state.referenceId}</strong>
                                                    </h5>
                                                    <h5 className="mb-3">
                                                        Amount to wire transfer: <strong className="text-primary">{this.state.amount + this.state.commission + Math.floor(this.state.baseFee * 2)} <small>€</small></strong>
                                                    </h5>
                                                    <hr />
                                                    <h5 className="mb-3">
                                                        Amount to receive: <strong>{this.state.amount} <small>EUROP</small></strong>
                                                    </h5>
                                                    {this.state.commission > 0 && <h5 className="mb-3">
                                                        Commission : <strong>{this.state.commission} <small>€</small></strong>
                                                    </h5>}
                                                    {this.state.baseFee > 0 && <h5 className="mb-3">
                                                        Chain fees : <strong>{this.state.baseFee * 2} <small>€</small></strong>
                                                    </h5>}
                                                    <p className="mt-3">
                                                        By validating the request, client agrees to wire transfer <strong className="ml-1 mr-1">{this.state.amount + this.state.commission + Math.floor(this.state.baseFee * 2)}€</strong> from
                                                        <span className="ml-1 mr-1">{(this.state.selectedIban || this.state.iban) && IBAN.printFormat(this.state.selectedIban || this.state.iban, '-')}</span> to
                                                        <strong className="ml-1 mr-1">Schuman Financial {(this.state.client && this.state.client.receivingBank && IBAN.printFormat(this.state.client.receivingBank, '-')) || (this.state.salvusIban && IBAN.printFormat(this.state.salvusIban, '-'))}</strong> with reference ID <strong className="ml-1 mr-1">{this.state.referenceId}</strong>
                                                    </p>
                                                    <p className="mt-3">
                                                        EUROP funds will be minted on <strong>{this.state.chain}</strong> chain and sent to wallet: <br />
                                                        <strong className="text-primary">{this.state.selectedWallet}</strong>
                                                    </p>
                                                    <div className="mt-4">
                                                        {this.state.pending ?
                                                            <p className="text-primary">Processing request... Please wait.</p>
                                                            :
                                                            <Button type="button" color="primary" onClick={this.registerRequest}>Validate purchase request</Button>
                                                        }
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId="3" id="final">
                                                    <h5 className="mb-4 text-success">Purchase request submitted</h5>
                                                    <p>A notification & summary mail has been sent</p>
                                                    <h4><strong>Important</strong>: do not forget to include the following reference in the wire transfer</h4>
                                                    <h5 className="mb-3">
                                                        <strong className="text-primary">{this.state.referenceId}</strong>
                                                        <span>
                                                            {!this.state.copied ?
                                                                <small className="badge badge-primary clickable ml-2" onClick={() => { this.copyReference() }}>copy</small>
                                                                :
                                                                <small className="badge text-success pointer ml-2">copied!</small>
                                                            }
                                                        </span>
                                                    </h5>
                                                    <h5 className="mb-3">
                                                        Amount to wire transfer: <strong className="text-primary">{this.state.amount + this.state.commission + Math.floor(this.state.baseFee * 2)} <small>€</small></strong>
                                                    </h5>

                                                    <p className="mt-2">Purchase request submitted and awaiting <strong className="ml-1 mr-1">{this.state.amount + this.state.commission + Math.floor(this.state.baseFee * 2)}€</strong> funds on
                                                        <strong className="ml-1 mr-1">Schuman Financial {(this.state.client && this.state.client.receivingBank && IBAN.printFormat(this.state.client.receivingBank, '-')) || (this.state.salvusIban && IBAN.printFormat(this.state.salvusIban, '-'))}</strong> account</p>
                                                </TabPane>
                                            </TabContent>
                                        </div>

                                    </CardBody>

                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Purchase;