import React, { Component } from 'react';

import { Row, Col, Card, Container } from "reactstrap";
import { withRouter } from 'react-router-dom';

import Copyright from '../../../components/Common/Copyright'
import Welcome from './welcome'
import Cgu from './cgu'
import CompanyInfo from './companyinfo'
import Owners from './owners'
import TxInfo from './txinfo'
import DocUpload from './docupload'
import Complete from './complete'
import back from "../../../assets/images/back.jpg"

import { registerOwner, registerCompany } from "../../../helpers/api"

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: 'welcome',
            owners: [],
            company: {},
            txinfo: {},
            kbis: {},
            incorporation: {},
        }
    }

    handleChangeView = (view) => {
        this.setState({ view })
    }

    submitOwners = (owners) => {
        this.setState({ owners, view: "tx_info" })
        let salvusReg = JSON.parse(window.localStorage.getItem('salvus_registration'))
        salvusReg.owners = owners
        window.localStorage.setItem('salvus_registration', JSON.stringify(salvusReg))
    }

    submitCompany = (company) => {
        this.setState({ company, view: "owners" })
        if (window.localStorage.getItem('salvus_registration')) {
            let salvusReg = JSON.parse(window.localStorage.getItem('salvus_registration'))
            salvusReg.company = company
        } else {
            let salvusReg = {}
            salvusReg.company = company
            window.localStorage.setItem('salvus_registration', JSON.stringify(salvusReg))
        }
    }

    submitTxinfo = (txinfo) => {
        this.setState({ txinfo, view: "doc" })
        let salvusReg = JSON.parse(window.localStorage.getItem('salvus_registration'))
        salvusReg.txinfo = txinfo
        window.localStorage.setItem('salvus_registration', JSON.stringify(salvusReg))
    }

    submitKbis = (kbis) => {
        this.setState({ kbis })
    }

    submitIncorporation = (incorporation) => {
        this.setState({ incorporation })
    }

    submitOwnerDoc = (owners) => {
        this.setState({ owners })
    }

    handleChange = (value) => {
      this.setState({ value });
      // if value is null recaptcha expired
      if (value === null) this.setState({ expired: "true" });
    };

    submitForm = async () => {
        const resp = await registerCompany({
            companyName: this.state.company.companyName,
            businessSector: this.state.company.businessSector,
            registrationNumber: this.state.company.registrationNumber,
            fullAddress: this.state.company.fullAddress,
            legalForm: this.state.company.legalForm,
            purpose: this.state.company.purpose,
            contactMail: this.state.company.contactMail,
            iban: this.state.txinfo.iban,
            bic: this.state.txinfo.bic,
            tezosPkh: this.state.txinfo.tezosPkh,
            ethPkh: this.state.txinfo.ethPkh,
            incorporationData: this.state.incorporation.incorporationData,
            incorporationExtension: this.state.incorporation.incorporationExtension,
            kbisData: this.state.kbis.kbisData,
            kbisExtension: this.state.kbis.kbisExtension,
            recaptcha: this.state.value,
        })
        if (resp.status === "SUCCESS") {
            const companyId = resp.companyId
            for (let owner of this.state.owners) {
                const res_own = await registerOwner({
                    isHolder: owner.hold,
                    isController: owner.control,
                    isLegal: owner.legal,
                    lastname: owner.lastname,
                    firstname: owner.firstname,
                    birthdate: owner.birthdate,
                    birthcity: owner.birthcity,
                    birthcountry: owner.birthcountry,
                    companyId,
                    idData: owner.idDoc && owner.idDoc.fileData,
                    idExtension: owner.idDoc && owner.idDoc.fileExtension,
                    uboData: owner.uboDoc && owner.uboDoc.fileData,
                    uboExtension: owner.uboDoc && owner.uboDoc.fileExtension,
                })
                if(res_own.status === "ERROR")
                    return false
            }
            return true
        } else {
            return false
        }
    }

    componentDidMount = () => {
        try {
            if (window.localStorage.getItem('salvus_registration')) {
                const salvusReg = JSON.parse(window.localStorage.getItem('salvus_registration'))
                this.setState({
                    owners: salvusReg.owners || [],
                    company: salvusReg.company || {},
                    txinfo: salvusReg.txinfo || {},
                    kbis: salvusReg.kbis || {},
                    incorporation: salvusReg.incorporation || {},
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="account-pages pt-sm-5 bg-welcome" style={{ backgroundImage: `url('${back}')` }}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={10} lg={8} xl={6}>
                                <div className="wcard-schuman">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                        </Col>
                                    </Row>
                                </div>
                                <Card className="overflow-hidden card-welcome">
                                    {this.state.view === 'welcome' && <Welcome handleChangeView={this.handleChangeView} />}
                                    {this.state.view === 'cgu' && <Cgu handleChangeView={this.handleChangeView} />}
                                    {this.state.view === 'company_info' && <CompanyInfo submitCompany={this.submitCompany}
                                        handleChangeView={this.handleChangeView}
                                        company={this.state.company} />}
                                    {this.state.view === 'owners' && <Owners handleChangeView={this.handleChangeView}
                                        submitOwners={this.submitOwners}
                                        owners={this.state.owners} />}
                                    {this.state.view === 'tx_info' && <TxInfo handleChangeView={this.handleChangeView}
                                        submitTxinfo={this.submitTxinfo}
                                        txinfo={this.state.txinfo} />}
                                    {this.state.view === 'doc' && <DocUpload handleChangeView={this.handleChangeView}
                                        kbis={this.state.kbis}
                                        incorporation={this.state.incorporation}
                                        submitIncorporation={this.submitIncorporation}
                                        submitKbis={this.submitKbis}
                                        submitOwnerDoc={this.submitOwnerDoc}
                                        submitForm={this.submitForm}
                                        handleChange={this.handleChange}
                                        value={this.state.value}
                                        owners={this.state.owners} />}
                                    {this.state.view === 'complete' && <Complete />}
                                </Card>
                                <div className="mt-5 text-center">
                                    <Copyright />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Register);

