import * as React from "react"

function SVGLogo(props) {
  return (
    <svg width={props.width || "20"} height={props.height || "20"} {...props} viewBox="0 0 1080 1200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2737_909)">
    <path d="M50 672.528V760.187L50.892 766.912C53.5725 787.118 61.2518 806.337 73.2344 822.827L75.9784 826.603C89.445 845.135 108.446 858.915 130.245 865.957V865.957C140.991 869.429 152.215 871.197 163.509 871.197H558.273C574.901 871.197 591.262 867.025 605.86 859.063V859.063C618.933 851.932 630.237 841.954 638.935 829.867L640.609 827.541C650.215 814.192 656.763 798.89 659.786 782.723L661.415 774.01C663.135 764.814 664 755.48 664 746.125V679.035V550.914C664 538.3 660.68 525.909 654.373 514.985V514.985C646.702 501.697 634.979 491.214 620.92 485.069L237.951 317.679" stroke="black" stroke-width="100" stroke-linejoin="round"></path>
    <path d="M664 335.67V248.01L663.108 241.286C660.427 221.079 652.748 201.86 640.766 185.37L638.022 181.594C624.555 163.062 605.554 149.283 583.755 142.24V142.24C573.009 138.768 561.785 137 550.491 137H155.727C139.099 137 122.738 141.172 108.14 149.134V149.134C95.0668 156.265 83.7632 166.243 75.0649 178.33L73.3914 180.656C63.7847 194.006 57.237 209.308 54.214 225.474L52.5846 234.188C50.8652 243.383 50 252.717 50 262.072V329.162V457.284C50 469.897 53.3201 482.288 59.6266 493.212V493.212C67.2979 506.5 79.0209 516.983 93.0799 523.128L476.049 690.519" stroke="black" stroke-width="100" stroke-linejoin="round"></path>
    </g>
    </svg>
  )
}

export default SVGLogo