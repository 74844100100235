import React, { Component } from 'react';

import { Row, Col, Card, CardTitle, CardFooter, CardBody, Input} from "reactstrap";

import { Line } from 'rc-progress';

class Owners extends Component {

    constructor(props) {
        super(props);
        this.state = {
            owners: this.props.owners,
            firstname: '',
            lastname: '',
            birthdate: '',
            birthcity: '',
            birthcountry: '',
            hold: false,
            control: false,
            legal: false,
            error: null,
        }
    }

    registerOwner = () => {
        if(this.state.owners.length > 14) {
                this.setState({error: 'Cannot register more owners'})
        }
        else if( !this.state.hold && !this.state.control && !this.state.legal ) {
                this.setState({error: 'Please select at least one option'})
        }
        else if( !this.state.firstname || 
            !this.state.lastname ||  
            !this.state.birthdate ||  
            !this.state.birthcity ||  
            !this.state.birthcountry) {
                this.setState({error: 'Please fill all required information for current owner'})
        }else{
            const owner = {
                firstname: this.state.firstname,
                lastname: this.state.lastname,
                birthdate: this.state.birthdate,
                birthcity: this.state.birthcity,
                birthcountry: this.state.birthcountry,
                legal: this.state.legal,
                control: this.state.control,
                hold: this.state.hold,
            }
            this.setState({
                owners: [...this.state.owners, owner],
                firstname: '',
                lastname: '',
                birthdate: '',
                birthcity: '',
                birthcountry: '',
                legal: false,
                hold: false,
                control: false,
                error: null,
            })
        }
    }

    removeOwner = (n) => {
        let owners = this.state.owners
        owners.splice(n, 1)
        this.setState({owners})
    }

    render() {
        return (
            <CardBody>
                <div>
                    <h5>
                        3. Representative and beneficial owners
                    </h5>
                    <Line percent={30} strokeWidth={2} strokeColor="#2eecc7" />
                    {this.state.owners.length > 0 && <h5 className="mt-3 mb-2">
                        <strong>Registered Owners</strong>
                    </h5>}
                    {this.state.owners.map((owner, i) =>
                        <Card key={i}>
                            <CardTitle className="pt-3 px-4">
                                #{i + 1} {owner.firstname} {owner.lastname} <small>born {owner.birthdate} at {owner.birthcity} - {owner.birthcountry}</small>
                            </CardTitle>
                            <CardBody className="pt-0 mt-0">
                                {owner.hold && <span className="d-block ml-2">Hold more than 25% of the capital and/or voting rights (UBO <small>Ultimate Beneficial Owner</small>)</span>}
                                {owner.control && <span className="d-block ml-2">Exercise control over the company</span>}
                                {owner.legal && <span className="d-block ml-2">Legal representative</span>}
                            </CardBody>
                            <CardFooter>
                                <button className="badge badge-dark font-size-10 pointer" onClick={() => this.removeOwner(i)}><i className="mdi mdi-close"></i> Remove</button>
                            </CardFooter>
                        </Card>
                    )}
                    {this.state.owners.length > 0 && <hr/>}
                    <Row className="mt-3">
                        <Col className="col-1">
                            <h1 className="m-0">
                                <i className="mdi mdi-account-key text-primary text-right"></i>
                            </h1>
                        </Col>
                        <Col className="col-10 align-self-end">
                            <p>
                                Please list <strong>all ultimate beneficial owners</strong> and representatives of your company.
                                Click on <strong>Register & add owner</strong> to register owners and representatives one after another.
                            </p>
                        </Col>
                    </Row>
                    <div className="form-group">
                        <h5 className="mt-2 mb-1">
                            <strong>Register Owner {this.state.owners.length + 1}</strong>
                        </h5>
                        <p>
                            Select the correct option <span className="text-danger">*</span>
                        </p>
                    </div>
                    <Row>
                        <Col className="col-1">
                            <Input type="checkbox" value="hold" className="mx-2" onClick={() => this.setState({hold: !this.state.hold, control: false, legal: false})} checked={this.state.hold}/> 
                        </Col>
                        <Col className="col-10 mt-1">
                            <p>
                                Hold more than 25% of the capital and/or voting rights
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-1">
                            <Input type="checkbox" value="control" className="mx-2" onClick={() => this.setState({control: !this.state.control, hold: false, legal: false})} checked={this.state.control}/> 
                        </Col>
                        <Col className="col-10 mt-1">
                            <p>
                                Exercise control over the company
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-1">
                            <Input type="checkbox" value="legal" className="mx-2" onClick={() => this.setState({legal: !this.state.legal, hold: false, control: false})} checked={this.state.legal}/> 
                        </Col>
                        <Col className="col-10 mt-1">
                            <p>
                                Legal representative
                            </p>
                        </Col>
                    </Row>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Lastname</strong> <span className="text-danger">*</span></p>
                        <Input onChange={(e) => this.setState({lastname: e.target.value})} value={this.state.lastname} className="form-control" placeholder="Lastname" type="text" required />
                    </div>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Firstname</strong> <span className="text-danger">*</span></p>
                        <Input onChange={(e) => this.setState({firstname: e.target.value})} value={this.state.firstname} className="form-control" placeholder="Firstname" type="text" required />
                    </div>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Birth date</strong> <span className="text-danger">*</span></p>
                        <Input onChange={(e) => this.setState({birthdate: e.target.value})} value={this.state.birthdate} className="form-control" placeholder="Date of birth" type="date" required />
                    </div>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>City of birth</strong> <span className="text-danger">*</span></p>
                        <Input onChange={(e) => this.setState({birthcity: e.target.value})} value={this.state.birthcity} className="form-control" placeholder="City of birth" type="text" required />
                    </div>
                    <div className="form-group">
                        <p className="mt-2 mb-1"><strong>Country of birth</strong> <span className="text-danger">*</span></p>
                        <Input onChange={(e) => this.setState({birthcountry: e.target.value})} value={this.state.birthcountry} className="form-control" placeholder="Country of birth" type="text" required />
                    </div>
                    <p>
                        <span className="text-danger">*</span> <small>All mandatory fields</small>
                    </p>
                    {this.state.error && <p className="my-1 text-danger">
                        <strong>{this.state.error}</strong>
                    </p>}
                    <Row className="mt-3">
                        <Col className="col-6">
                            <div className="btn btn-dark btn-block waves-effect waves-light" onClick={this.registerOwner}>
                                Register & add owner
                            </div>
                        </Col>
                    </Row>
                    <hr/>
                    <Row className="mt-3">
                        <Col className="col-4">
                            <div className="btn btn-secondary btn-block waves-effect waves-light" onClick={() => this.props.handleChangeView('company_info')}>
                                {"<"} Back
                            </div>
                        </Col>
                        <Col className="col-8">
                            <div className="btn btn-primary btn-block waves-effect waves-light" onClick={() => this.state.owners.length > 0 ?
                                                                                                                this.props.submitOwners(this.state.owners)
                                                                                                              : this.setState({error: 'Please register at least one owner or representative'})}>
                                Validate owners
                            </div>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        );
    }
}

export default Owners;