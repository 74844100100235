import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class CguContent extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="cgu_content">
                    <h5>CONDITIONS GENERALES D’UTILISATION DE LA MONNAIE ELECTRONIQUE EUROP</h5>
                    <p className="mt-2">
                        <strong>Préambule</strong>
                    </p>
                    <small className="d-block">
                        Les présentes conditions générales d’utilisation de la monnaie électronique EUROP (désignées ci-après
                        les « Conditions Générales ») régissent l'ensemble des relations entre : 
                    </small>
                    <small className="d-block mt-2 ml-4">
                    ● D’une part, Schuman Financial, Société par Actions Simplifiée au capital de 1000 €, dont le siège social
                    est situé au 9 rue du quatre septembre à Paris (75002) immatriculée sous le numéro 920 017
                    134 habilitée à exercer son activité en qualité d’« établissement de monnaie électronique »
                    agréé par l’Autorité de Contrôle Prudentiel et Résolution, ci-après dénommé l’« Emetteur », et
                    </small>
                    <small className="d-block mt-2 ml-4">
                    ● D’autre part, toute personne physique ou morale qui utilise la monnaie électronique émise par
                    l’Emetteur, ci-après dénommé « le Détenteur»,
                    </small>
                    <small className="d-block mt-2">
                    Ensemble ci-après dénommées « les Parties »
                    </small>
                    <p className="ml-2 mt-3">
                        <strong>1. Objet du contrat</strong>
                    </p>
                    <small className="d-block">
                        Les Conditions Générales visent à décrire les conditions et modalités d’utilisation et de
                        remboursement auprès de l’Emetteur de la monnaie électronique EUROP (ci-après la « Monnaie
                        électronique ») entre Schuman Financial et le Détenteur.
                    </small>
                    <small className="d-block mt-2">
                        Elles expriment l&#39;intégralité des obligations et des droits des Parties.
                    </small>
                    <small className="d-block mt-2">
                        Le Détenteur dispose de la faculté de les imprimer ou de les enregistrer au format PDF en cliquant <Link to="#download">ici</Link>.
                        Il peut également demander à recevoir les présentes Conditions Générales, ainsi que les informations
                        et conditions prévues à l’article 2 de l’arrêté du 29 juillet 2009 relatif aux relations entre les prestataires
                        de services de paiement et leurs clients en matière d’obligations d’information des utilisateurs de
                        services de paiement.
                    </small>
            </div>
        );
    }
}

export default CguContent;