import React, { Component } from 'react';

import { Row, Col, CardBody , Input} from "reactstrap";

import { Link } from 'react-router-dom';

import { Line } from 'rc-progress';

import CguContent from './cgucontent';

class Cgu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            checkOne: false,
            error: null,
        }
    }

    verify = () => {
        if(!this.state.checkOne){
            this.setState({error: "Please agree conditions to start registration"})
        }else{
            this.setState({error: null})
            this.props.handleChangeView('company_info')
        }
    }

    render() {
        return (
            <CardBody>
                <div>
                    <h5>
                        1. General terms and conditions of use
                    </h5>
                    <Line percent={10} strokeWidth={2} strokeColor="#2eecc7" />
                    <Row className="mt-4">
                        <Col className="col-xl-1 col-md-2">
                            <h1 className="m-0">
                                <i className="mdi mdi-check-circle-outline text-primary text-right"></i>
                            </h1>
                        </Col>
                        <Col className="col-10 align-self-end">
                            <p>
                                By signing this agreement, you agree that the information you provide is
                                trustful. You agree to abide by the terms of the agreement below. 
                            </p>
                            <CguContent/>
                            <p>
                                <strong><Link to="#download">Download terms</Link></strong>
                            </p>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col className="col-xl-1 col-md-2">
                            <Input type="checkbox" className="mx-4" checked={this.state.checkOne} onChange={() => this.setState({checkOne: !this.state.checkOne})}/> 
                        </Col>
                        <Col className="col-10 mt-1">
                            <p>
                                I agree to the general terms and conditions
                            </p>
                        </Col>
                    </Row>
                    {this.state.error && <p className="my-1 text-danger">
                        <strong>{this.state.error}</strong>
                    </p>}
                    <Row className="mt-3">
                        <Col className="col-4">
                            <div className="btn btn-secondary btn-block waves-effect waves-light" onClick={() => this.props.handleChangeView('welcome')}>
                                {"<"} Back
                            </div>
                        </Col>
                        <Col className="col-8">
                            <div className="btn btn-primary btn-block waves-effect waves-light" onClick={this.verify}>
                                Agree conditions
                            </div>
                        </Col>
                    </Row>
                </div>
            </CardBody>
        );
    }
}

export default Cgu;

