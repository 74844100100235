import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Media, Button, Label } from "reactstrap";
import Loader from 'react-loader-spinner';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

//i18n
import SVGLogo from '../../components/Common/SVGLogo.js';
import Fees from './components/Fees.js';
import { clientProfile } from '../../helpers/api.js';
import CredentialsModal from "./Modals/CredentialsModal.js";

class ClientInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: {},
            idbanks: [],
            credentialsModal: false,
            loading: true,
            emailModal: false,
            nameModal: false,
            vatNumberModal: false,
            ipAddressModal: false,
            displayIban: false,
            volumes: {},
            feeModel: null,
            reserveTezos: null,
            reserveEthereum: null,
            tokenTezos: null,
            tokenEthereum: null,
            apicredentials: {},
        };
    }

    toggleCredentialsModal = () => {
        this.setState({ credentialsModal: !this.state.credentialsModal })
    }

    loadClient = async () => {
        this.setState({ loading: true })
        const res = await clientProfile()
        this.setState({
            client: res.data.client, idbanks: res.data.idbanks, feeModel: res.data.feeModel,
            volumes: res.data.volumes, tokenTezos: res.data.fa2, tokenEthereum: res.data.erc20, loading: false,
            apicredentials: res.data.apicredentials,
        })
    }

    componentDidMount = () => {
        this.loadClient()
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {this.state.loading ?
                            <Loader type="Puff" color="#545454" height={50} width={50} />
                            :
                            <Row>
                                <Col xl="4">
                                    <Card className="overflow-hidden">
                                        {this.state.client.logo && <div className="wcard-client p-2"
                                            style={{ backgroundImage: `url('https://premiere.infura-ipfs.io/ipfs/${this.state.client.logo}')`, height: "20vw", backgroundPosition: 'center center', backgroundSize: 'cover' }}>
                                        </div>}
                                        <CardBody className="pt-2">
                                            <Row>
                                                <Col sm="6">
                                                    <h5 className="font-size-15 text-truncate mt-4 p-relative">
                                                        {this.state.client.name}
                                                    </h5>
                                                    <p className="text-muted mb-0 text-truncate p-relative">
                                                        VAT: {this.state.client.vatNumber ? this.state.client.vatNumber : 'Not provided'}
                                                    </p>
                                                </Col>

                                                <Col sm="6">
                                                    <div className="pt-4">
                                                        <Row>
                                                            <Col xs="12">
                                                                <h5 className="font-size-15">General contact</h5>
                                                                <p className="text-muted mb-0 font-size-11 p-relative">
                                                                    {this.state.client.email}
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>

                                                <Col sm="6">
                                                    <div className="pt-4">
                                                        <Row>
                                                            <Col xs="12">
                                                                <p className="text-muted mb-0 font-size-11 mb-2">Edit company profile</p>
                                                                <Button type="button" color="light" onClick={() => window.location='/edit'}>Edit profile</Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col sm="6">
                                                    <div className="pt-4">
                                                        <Row>
                                                            <Col xs="12">
                                                                <p className="text-muted mb-0 font-size-11 mb-2">Display API credentials</p>
                                                                <Button type="button" color="light" onClick={this.toggleCredentialsModal}>API credentials</Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="8">
                                    <Row>
                                        <Col md="12">
                                            <Card className="mini-stats-wid">
                                                <CardBody>
                                                    <Media>
                                                        <Media body>
                                                            <h4>
                                                                Purchase / sale volumes
                                                            </h4>
                                                            <hr />
                                                            {Object.keys(this.state.volumes).map((key, i) =>
                                                                <div key={`v_${i}`}>
                                                                    <Label className="mb-0">{key}</Label>
                                                                    <p className="mt-0">
                                                                        Volume: <SVGLogo width="14" height="14" />{this.state.volumes[key].volume.toLocaleString()}
                                                                        <small className="ml-2">(Mint: {this.state.volumes[key].mint.toLocaleString()} | Burn: {this.state.volumes[key].burn.toLocaleString()})</small>
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </Media>
                                                    </Media>
                                                </CardBody>
                                            </Card>
                                            {this.state.feeModel && <Fees feeModel={this.state.feeModel} />}
                                        </Col>
                                    </Row>
                                </Col>
                                <CredentialsModal credentialsModal={this.state.credentialsModal}
                                    toggleCredentialsModal={this.toggleCredentialsModal}
                                    apicredentials={this.state.apicredentials}
                                    loadClient={this.loadClient} />
                            </Row>
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user } = state.Login;
    return { user };
}

export default withRouter(connect(mapStatetoProps, null)(withTranslation()(ClientInfo)));
