import React, { Component } from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo.png";

//i18n
import { withTranslation } from 'react-i18next';


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      dropdownOpen: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.searchEntry = React.createRef();
  }
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }
  

  /**
   * Toggles the network selecter
   */
  toggleDropdown = () => {
      this.setState({dropdownOpen: !this.state.dropdownOpen});
  }

  handleKeyPress = (event) => {
      if(event.key === 'Enter'){
        if(this.searchEntry.current.value.length === 36 && this.searchEntry.current.value.includes('tz'))
            window.open(`https://tzstats.com/${this.searchEntry.current.value}`);
        else if(this.searchEntry.current.value.length === 36 && this.searchEntry.current.value.includes('KT'))
            window.open(`https://tzstats.com/${this.searchEntry.current.value}`);
        else if(this.searchEntry.current.value.length === 51)
            window.open(`https://tzstats.com/${this.searchEntry.current.value}`);
        else if(this.searchEntry.current.value.length === 42 && this.searchEntry.current.value.includes('0x'))
            window.open(`https://etherscan.io/address/${this.searchEntry.current.value}`);
        else if(this.searchEntry.current.value.length === 66 && this.searchEntry.current.value.includes('0x'))
            window.open(`https://etherscan.io/tx/${this.searchEntry.current.value}`);
        else
            console.log('Unrecognized format')
      }
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to={'/'} className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logoLightPng} alt="logo-schuman" height="32" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="logo-schuman" height="32" />
                  </span>
                </Link>

                <Link to={'/'} className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="logo-schuman" height="32" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="logo-schuman" height="32" />
                  </span>
                </Link>
              </div>

              <button type="button" onClick={this.toggleMenu} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                <i className="fa fa-fw fa-bars"></i>
              </button>
            </div>
            <div className="d-flex">
              {/*<LanguageDropdown />*/}

              {/*
              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button type="button" onClick={this.toggleFullscreen} className="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div>
              */}

              <ProfileMenu/>

            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}


const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  const { user } = state.Login;
  return { layoutType, user };
};

export default connect(mapStatetoProps)(withTranslation()(Header));

