import React, { Component } from "react";
import { Card, CardBody, Media, Label } from "reactstrap";

class Fees extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Card className="mini-stats-wid">
                <CardBody>
                    <Media>
                        <Media body>
                            <h4>
                                Fees model
                            </h4>
                            <hr />
                            {(!this.props.feeModel.endDate || Date.parse(this.props.feeModel.endDate) > Date.now()) ?
                                <div><span className="badge badge-success font-size-10">Active</span></div>
                                :
                                <div><span className="badge badge-danger font-size-10">Expired</span></div>
                            }
                            <Label className="d-block mt-2">
                                From <strong>{new Date(this.props.feeModel.startDate).toLocaleString()}</strong>
                                {this.props.feeModel.endDate &&
                                    <span className="ml-2">to <strong>{new Date(this.props.feeModel.endDate).toLocaleString()}</strong></span>
                                }
                            </Label>
                            <Label className="d-block mt-1">
                                Mint fees: <strong>{this.props.feeModel.mint}</strong>% | Burn fees: <strong>{this.props.feeModel.burn}</strong>%
                            </Label>
                            {(this.props.feeModel.euroFees || this.props.feeModel.cryptoFees || this.props.feeModel.baseEuro || this.props.feeModel.baseCrypto) ?
                                <div>
                                    <Label className="d-block mt-2 mb-0">
                                        OCA commissions
                                    </Label>
                                    <small className="d-block">
                                        Euro fees: <strong>{this.props.feeModel.euroFees}</strong>% | Base euro amount fees: <strong>{this.props.feeModel.baseEuro}</strong>%<br />
                                        Crypto fees: <strong>{this.props.feeModel.cryptoFees}</strong>% | Base crypto amount fees: <strong>{this.props.feeModel.baseCrypto}</strong>%
                                    </small>
                                </div>
                                :
                                <div className="d-block mt-1"><span className="badge badge-dark font-size-10">No commissions</span></div>
                            }
                            {this.props.feeModel.revenues && this.props.feeModel.revenues.length > 0 ?
                                <div>
                                    <Label className="d-block mt-2 mb-0">Subscription model</Label>
                                    {this.props.feeModel.revenues.map(revenue =>
                                        <small className="d-block mb-1" key={revenue.price}>
                                            <span className="badge badge-light font-size-10">{revenue.level}</span> {revenue.price}€
                                        </small>
                                    )}
                                </div>
                                :
                                <div className="d-block mt-1"><span className="badge badge-dark font-size-10">No subscription</span></div>
                            }
                        </Media>
                    </Media>
                </CardBody>
            </Card>
        );
    }
}

export default Fees;