import React, { Component } from "react";
import Loader from "react-loader-spinner";

import { getAccounts, rmUser, reactivateUser } from "../../helpers/api";
import { Container, Row, Col, Table, Alert } from "reactstrap";
import Can from "../../components/Can";

import { connect } from "react-redux";

import NewUser from "./new-user";

class Accounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      accounts: [],
      selectedWallet: null,
      updateModal: false,
      error: null,
      success: null,
    };
  }

  loadUsers = async () => {
    this.setState({ loading: true });
    const res = await getAccounts();
    this.setState({ accounts: res.data.accounts, loading: false });
  };

  reactivateUser = async (email) => {
    this.setState({ error: null, success: null, loading: true });
    const resp = await reactivateUser(email);
    console.log(resp);
    if (resp.error) this.setState({ error: resp.error, loading: false });
    else {
      this.setState({ success: "Enrollment code resent" });
      await this.loadUsers();
    }
  };

  removeUser = async (email) => {
    this.setState({ error: null, success: null, loading: true });
    const resp = await rmUser(email);
    console.log(resp);
    if (resp.error) this.setState({ error: resp.error, loading: false });
    else {
      this.setState({ success: "User successfully removed" });
      await this.loadUsers();
    }
  };

  componentDidMount = async () => {
    await this.loadUsers();
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {this.state.loading ? (
              <Loader type="Puff" color="#545454" height={50} width={50} />
            ) : (
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      {this.state.error && (
                        <Alert color="danger">{this.state.error}</Alert>
                      )}
                      {this.state.success && (
                        <Alert color="success">{this.state.success}</Alert>
                      )}
                      <Table className="project-list-table table-nowrap table-centered table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "100px" }}>
                              #
                            </th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">Active</th>
                            <th scope="col">Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.accounts &&
                            this.state.accounts.map((account) => (
                              <tr key={account.id}>
                                <td>
                                  {account.role === "CLIENT_ADMIN" ? (
                                    <i className="mdi mdi-account-cog text-dark h1"></i>
                                  ) : account.active ? (
                                    <i className="mdi mdi-account-check text-dark h1"></i>
                                  ) : (
                                    <i className="mdi mdi-account-cancel text-dark h1"></i>
                                  )}
                                </td>
                                <td>
                                  <p className="text-muted mb-0">
                                    {account.firstname}{" "}
                                    <strong>{account.lastname}</strong>
                                  </p>
                                </td>
                                <td>
                                  <h5 className="text-truncate font-size-14">
                                    <span className="text-dark">
                                      {account.email}
                                    </span>
                                  </h5>
                                </td>
                                <td>
                                  {account.role === "CLIENT_ADMIN" ? (
                                    <span className="badge badge-dark font-weight-bold">
                                      Administrator
                                    </span>
                                  ) : (
                                    <span className="badge badge-primary font-weight-bold">
                                      Client user
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <small>{account.active}</small>
                                  <br />
                                  {["PENDING_ACTIVATION", "INACTIVE"].includes(account.active) && (
                                    <Can
                                      role={this.props.user.role}
                                      perform="client:admin"
                                      yes={() => (
                                        <span
                                          className="badge badge-info font-weight-bold"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                `Send new enrollment TrustBuilder code to ${account.email}?`
                                              )
                                            ) {
                                              this.reactivateUser(
                                                account.email
                                              );
                                            }
                                          }}
                                        >
                                          Resend code
                                        </span>
                                      )}
                                    />
                                  )}
                                </td>
                                <td>
                                  {this.props.user.id !== account.id && (
                                    <Can
                                      role={this.props.user.role}
                                      perform="client:admin"
                                      yes={() => (
                                        <span
                                          className="badge badge-danger font-weight-bold"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            if (
                                              window.confirm(
                                                "Remove this account and deactivate this user's access? A TrustBuilder authentication validation will be required."
                                              )
                                            ) {
                                              this.removeUser(account.email);
                                            }
                                          }}
                                        >
                                          Remove account
                                        </span>
                                      )}
                                    />
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <Can
                        role={this.props.user.role}
                        perform="client:admin"
                        yes={() => (
                          <div className="mt-4">
                            <NewUser loadUsers={this.loadUsers} />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(Accounts);
