import React, { Component } from "react";
import Loader from "react-loader-spinner";

import { clientProfile, deleteIdbank } from "../../helpers/api";
import { Container, Row, Col, Table, Button } from "reactstrap";
import Can from "../../components/Can";

import { connect } from "react-redux";

class Idbanks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      client: {},
      idbanks: [],
      selectedIdbank: null,
      updateModal: false,
      pending: null,
      error: null,
    };
  }

  loadClient = async () => {
    this.setState({ loading: true });
    const res = await clientProfile();
    this.setState({
      client: res.data.client,
      idbanks: res.data.idbanks,
      loading: false,
    });
  };

  rmIdbank = async (iban) => {
    this.setState({ pending: iban, error: null, });
    try {
      await deleteIdbank(iban);
      this.loadClient();
    }catch(error) {
        this.setState({ error: error.toString() });
    }
    this.setState({ pending: null });
  };

  componentDidMount = () => {
    this.loadClient();
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {this.state.loading ? (
              <Loader type="Puff" color="#545454" height={50} width={50} />
            ) : (
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive">
                      {this.state.error && (
                        <p className="text-danger">{this.state.error}</p>
                      )}
                      <Table className="project-list-table table-nowrap table-centered table-borderless">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "100px" }}>
                              #
                            </th>
                            <th scope="col">Bank & IBAN</th>
                            <th scope="col">BIC</th>
                            <Can
                              role={this.props.user.role}
                              perform="client:admin"
                              yes={() => <th scope="col">Remove</th>}
                            />
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.idbanks &&
                            this.state.idbanks.map((idbank) => (
                              <tr key={idbank.iban}>
                                <td>
                                  <i className="mdi mdi-bank text-dark h1"></i>
                                </td>
                                <td>
                                  <h5 className="text-truncate font-size-14">
                                    <span className="text-dark">
                                      {idbank.bank ||
                                        `${
                                          this.state.client.name
                                        } ${idbank.bic.substring(0, 5)}`}
                                    </span>
                                  </h5>
                                  <p className="text-muted mb-0">
                                    {idbank.iban}
                                  </p>
                                </td>
                                <td>{idbank.bic}</td>
                                <Can
                                  role={this.props.user.role}
                                  perform="client:admin"
                                  yes={() => (
                                    <td>
                                      {this.state.pending ===  idbank.iban ? (
                                        <React.Fragment>
                                          <Loader
                                            type="Puff"
                                            color="#50a5f1"
                                            height={50}
                                            width={50}
                                          />
                                          <p className="mt-2 text-info">
                                            Notification sent onto your
                                            <br/>
                                            TrustBuilder Authenticator
                                            application.{" "}
                                            <br/>
                                            <strong>
                                              Set your pin code to update
                                              wallet...
                                            </strong>
                                          </p>
                                        </React.Fragment>
                                      ) : (
                                        <button className="btn btn-lg mr-1" onClick={() => {this.rmIdbank(idbank.iban)}}>
                                          <i className="bx bx-trash"></i>
                                        </button>
                                      )}
                                    </td>
                                  )}
                                />
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <Can
                        role={this.props.user.role}
                        perform="client:admin"
                        yes={() => (
                          <Button
                            type="button"
                            color="light"
                            onClick={() =>
                              (window.location.href = "/new-idbank")
                            }
                          >
                            Reference new Bank account
                          </Button>
                        )}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user } = state.Login;
  return { user };
};

export default connect(mapStatetoProps, null)(Idbanks);
