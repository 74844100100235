import React, { Component } from "react";
import { Row, Col, CardBody, Card, CardTitle, CardFooter } from "reactstrap";
import { Line } from "rc-progress";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import Loader from "react-loader-spinner";

import FileManager from "./filemanager";
import DocManager from "./docmanager";
import { RECAPTCHA_KEY } from "../../../constants";

class DocUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kbisData: this.props.kbis.kbisData,
      kbisExtension: this.props.kbis.kbisExtension,
      kbisName: this.props.kbis.kbisName,
      incorporationData: this.props.incorporation.incorporationData,
      incorporationExtension: this.props.incorporation.incorporationExtension,
      incorporationName: this.props.incorporation.incorporationName,
      owners: this.props.owners,
      processing: false,
    };
  }

  kbisCallback = (kbisData, kbisExtension, kbisName) => {
    this.setState({ kbisData, kbisExtension, kbisName });
    this.props.submitKbis({ kbisData, kbisExtension, kbisName });
  };

  incorporationCallback = (
    incorporationData,
    incorporationExtension,
    incorporationName
  ) => {
    this.setState({
      incorporationData,
      incorporationExtension,
      incorporationName,
    });
    this.props.submitIncorporation({
      incorporationData,
      incorporationExtension,
      incorporationName,
    });
  };

  ownerIdCallback = (fileData, fileExtension, fileName, index) => {
    let owners = [],
      i = 0;
    for (let owner of this.state.owners) {
      if (i === index) owner.idDoc = { fileData, fileExtension, fileName };
      owners.push(owner);
      i++;
    }
    this.setState({ owners });
    this.props.submitOwnerDoc(owners);
  };

  ownerUboCallback = (fileData, fileExtension, fileName, index) => {
    let owners = [],
      i = 0;
    for (let owner of this.state.owners) {
      if (i === index) owner.uboDoc = { fileData, fileExtension, fileName };
      owners.push(owner);
      i++;
    }
    this.setState({ owners });
    this.props.submitOwnerDoc(owners);
  };

  validateDocument = async () => {
    if (!this.state.incorporationData)
      return this.setState({ error: "Please upload all required documents" });
    else {
      console.log(this.state.owners);
      for (let owner of this.state.owners) {
        if (!owner.idDoc)
          return this.setState({
            error: `Id document for ${owner.firstname} ${owner.lastname} is missing`,
          });
        if (owner.hold && !owner.uboDoc)
          return this.setState({
            error: `UBO document for ${owner.firstname} ${owner.lastname} is missing`,
          });
      }
      if (!this.props.value)
        return this.setState({ error: `Please validate ReCaptcha to submit` });
      this.setState({ processing: true });
      const status = await this.props.submitForm();
      if (status) {
        window.localStorage.removeItem("salvus_registration");
        this.props.handleChangeView("complete");
      } else {
        this.setState({
          processing: false,
          error: "Error: Could not complete the registration.",
        });
      }
    }
  };

  render() {
    return (
      <CardBody>
        <div>
          <h5>5. Documents upload</h5>
          <Line percent={80} strokeWidth={2} strokeColor="#2eecc7" />
          <Row className="mt-4">
            <Col className="col-xl-1 col-md-2">
              <h1 className="m-0">
                <i className="mdi mdi-file-document text-primary text-right"></i>
              </h1>
            </Col>
            <Col className="col-10 align-self-end">
              <p>
                Please upload required documents to finalize your registration
                process for validation by our team.
              </p>
            </Col>
          </Row>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>Select certificate of incorporation</strong>{" "}
              <span className="text-danger">*</span>
            </p>
            <FileManager
              cb={this.incorporationCallback}
              fileData={{
                fileData: this.state.incorporationData,
                fileExtension: this.state.incorporationExtension,
                fileName: this.state.incorporationName,
              }}
            />
          </div>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>
                KBIS extract / local country equivalent{" "}
                <span className="text-info">*</span>
              </strong>
              <br />
              <small>German KBIS : Handelsregisterauszug</small>
              <br />
              <small>Spanish KBIS: Certificado de Registro Mercantil</small>
              <br />
              <small>Italian KBIS : Visura Camerale</small>
              <br />
              <small>Belgium KBIS : BCE</small>
              <br />
              <small>Portugese KBIS : Certidão do Registo Comercial</small>
              <br />
              <small className="text-info">*if applicable</small>
            </p>
            <FileManager
              cb={this.kbisCallback}
              fileData={{
                fileData: this.state.kbisData,
                fileExtension: this.state.kbisExtension,
                fileName: this.state.kbisName,
              }}
            />
          </div>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>Owners and legal representatives</strong>
            </p>
            {this.state.owners.map((owner, i) => (
              <Card key={i} className="bg-light">
                <CardTitle className="pt-3 px-4">
                  #{i + 1} {owner.firstname} {owner.lastname}{" "}
                  <small>
                    born {owner.birthdate} at {owner.birthcity} -{" "}
                    {owner.birthcountry}
                  </small>
                </CardTitle>
                <CardBody className="pt-0 mt-0">
                  {owner.hold && (
                    <span className="d-block ml-2">
                      Ultimate Beneficial Owner (UBO)
                    </span>
                  )}
                  {owner.control && (
                    <span className="d-block ml-2">
                      Exercise control over the company
                    </span>
                  )}
                  {owner.legal && (
                    <span className="d-block ml-2">Legal representative</span>
                  )}
                </CardBody>
                <CardFooter>
                  <small className="d-block mb-1">
                    Upload required document(s)
                  </small>
                  <DocManager
                    cb={this.ownerIdCallback}
                    id={true}
                    index={i}
                    fileData={owner.idDoc || {}}
                  />
                  {owner.hold && (
                    <DocManager
                      cb={this.ownerUboCallback}
                      id={false}
                      index={i}
                      fileData={owner.uboDoc || {}}
                    />
                  )}
                </CardFooter>
              </Card>
            ))}
          </div>
          <p>
            <span className="text-danger">*</span>{" "}
            <small>All mandatory fields</small>
          </p>
          {this.state.error && (
            <p className="my-1 text-danger">
              <strong>{this.state.error}</strong>
            </p>
          )}
          <HCaptcha
            sitekey={RECAPTCHA_KEY}
            onVerify={this.props.handleChange}
          />
          {this.state.processing ? (
            <>
              <Loader type="Puff" color="#545454" height={50} width={50} />
              <p>Processing... It might take a min. Please wait.</p>
            </>
          ) : (
            <Row className="mt-3">
              <Col className="col-4">
                <div
                  className="btn btn-secondary btn-block waves-effect waves-light"
                  onClick={() => this.props.handleChangeView("tx_info")}
                >
                  {"<"} Back
                </div>
              </Col>
              <Col className="col-8">
                <div
                  className="btn btn-primary btn-block waves-effect waves-light"
                  onClick={this.validateDocument}
                >
                  Complete registration
                </div>
              </Col>
            </Row>
          )}
        </div>
      </CardBody>
    );
  }
}

export default DocUpload;
