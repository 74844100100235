import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
//import Login from "../pages/Authentication/Login";
import Register from "../pages/Authentication/Register";
/* import ResetPassword from "../pages/Authentication/ResetPassword"; */
import Logout from "../pages/Authentication/Logout";
/* import ForgetPwd from "../pages/Authentication/ForgetPassword"; */

 // Inner Authentication
/* import Login1 from "../pages/AuthenticationInner/Login";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"; */

// Request - Client side
import Purchase from "../pages/Requests/Purchase";
import Sale from "../pages/Requests/Sale";
import Listing from "../pages/Requests/Listing";
import ClientInfo from "../pages/Profile/ClientInfo";
import Wallets from "../pages/Wallets";
import NewWallet from "../pages/Wallets/new";
import Idbanks from "../pages/Idbanks";
import NewIdbank from "../pages/Idbanks/new";
import Info from "../pages/Info";
import EditProfile from "../pages/Profile/Edit";
import Accounts from "../pages/Accounts";
import TrustBuilder from "../pages/Authentication/TrustBuilder";

const authProtectedRoutes = [

	// Client action paths
	{ path: "/request/purchase", exact: true, component: Purchase, action: "client:requests"},
	{ path: "/request/sale", exact: true, component: Sale, action: "client:requests"},
	{ path: "/request/listing", exact: true, component: Listing, action: "client:requests"},
	{ path: "/company", exact: true, component: ClientInfo, action: "client:requests"},
	{ path: "/wallets", exact: true, component: Wallets, action: "client:requests"},
	{ path: "/new-wallet", exact: true, component: NewWallet, action: "client:requests"},
	{ path: "/idbanks", exact: true, component: Idbanks, action: "client:requests"},
	{ path: "/new-idbank", exact: true, component: NewIdbank, action: "client:requests"},
	{ path: "/info", exact: true, component: Info, action: "client:requests"},
	{ path: "/edit", exact: true, component: EditProfile, action: "client:requests"},
	{ path: "/accounts", exact: true, component: Accounts, action: "client:requests"},

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/company" /> },
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: TrustBuilder },
	{ path: "/register", component: Register },
	/* { path: "/reset-password", component: ResetPassword },
	{ path: "/forgot-password", component: ForgetPwd }, */

	// Authentication Inner
	/* { path: "/pages-login", component: Login1 },
	{ path: "/pages-forgot-pwd", component: ForgetPwd1 },
	{ path: "/auth-lock-screen", component: LockScreen } */
];

export { authProtectedRoutes, publicRoutes };
