import axios from 'axios'
import * as rdd from 'react-device-detect'
import { AUTH_TOKEN, API_URL } from '../constants'

const scApi = axios.create({
  baseURL: API_URL,
  timeout: 120000,
  headers: {},
});

// Add a request interceptor
scApi.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token || ''}`;
  }
  return config;
});

const call = async (method, path, data) => {
  try {
    return (await scApi[method.toLowerCase()](path, data)).data
  } catch (e) {
    console.error(e)
  }

  return { status: 'ERROR' }
}

//Login a user using inwebo
export const loginInwebo = async (email, recaptcha) => {
  const device = {osName: rdd.osName, osVersion: rdd.osVersion, browserName: rdd.browserName}
  let res = await call("POST", '/auth/client/trustbuilder', { email, device, recaptcha })
  if (res.status === 'SUCCESS') {
    return res
  } else {
    throw new Error(JSON.stringify({
      err: res.error || res.message || 'UNKNOWN',
    }))
  }
}

//Login a user using email password and 2FA code with Google Authenticator
export const loginUser = async (email, password, code, recaptcha) => {
  const device = { osName: rdd.osName, osVersion: rdd.osVersion, browserName: rdd.browserName }
  console.log(device)
  let res = await call("POST", '/auth/login', { email, password, code, device, recaptcha })
  if (res.status === 'SUCCESS') {
    if (res.user && !res.user.client)
      throw new Error(JSON.stringify({
        err: 'WRONG_PF',
        remaining: null
      }))
    else
      return res
  } else {
    throw new Error(JSON.stringify({
      err: res.error || res.message || 'UNKNOWN',
      remaining: res.remaining || null
    }))
  }
}

//Get current user
export const getUser = async () => {
  let res = await call("GET", '/client/check')
  if (res.status === 'SUCCESS') {
    return res
  } else {
    return {
      status: 'ERROR'
    }
  }
}

//Update user's password
export const updateUserPassword = async (k, password) => {
  return call("PUT", `/auth/${k}/password`, { password })
}

//Authorize reset password
export const getResetPassword = async (email) => {
  return call("POST", `/auth/reset`, { email })
}

//Save company
export const registerCompany = async (company) => {
  return call("POST", `/auth/register/company`, company)
}

//Save owner (UBO)
export const registerOwner = async (owner) => {
  return call("POST", `/auth/register/owner`, owner)
}

//Validate reset key
export const checkResetKey = async (k) => {
  return call("GET", `/auth/check-reset?k=${k}`)
}

//TODO: Reset password
export const forgetPassword = async (email) => { }

// CLIENTS

//Fetch requests for current client
export const clientRequests = async () => {
  return call("GET", '/client/requests')
}

//Get all wallet info for client
export const clientProfile = async () => {
  return call("GET", '/client/profile')
}

//Get pending requests
export const pendingRequests = async () => {
  return call("GET", '/client/pending/requests')
}

//Cancel request
export const clientCancelRequest = async (reference) => {
  return call("DELETE", `/client/cancel/${reference}`)
}

//Register new request
export const clientSaveRequest = async (reference, amount, description, iban, action, publicKeyHash, chain) => {
  let res = await call("POST", '/client/request/save', { reference, amount, description, iban, action, publicKeyHash, chain })
  if (res.status === 'SUCCESS') {
    return res
  } else {
    throw new Error(res.error || 'UNKNOWN')
  }
}

//Get client's idbanks
export const clientIdbanks = async () => {
  return call("GET", `/client/idbanks`)
}

// GET Clients /client/fee/:publicKeyHash
export const feeClient = async () => {
  return call("GET", `/client/fee`)
}

// GET Base fee
export const getBaseFee = async () => {
  return call("GET", `/client/basefee`)
}

export const referenceWallet = async (pkh, name, description, chain) => {
  let res = await call("POST", '/client/wallet/new', { pkh, name, description, chain })
  if (res.status === 'SUCCESS') {
    return res
  } else {
    throw new Error(res.error || 'UNKNOWN')
  }
}

export const referenceIban = async (bank, iban, bic) => {
  let res = await call("POST", '/client/idbank/new', { bank, iban, bic })
  if (res.status === 'SUCCESS') {
    return res
  } else {
    throw new Error(res.error || 'UNKNOWN')
  }
}

// POST Update profile
export const updateProfile = async (name, email, logo, vatNumber) => {
  let res = await call("POST", '/client/info/update', { name, email, logo, vatNumber })
  if (res.status === 'SUCCESS') {
    return res
  } else {
    throw new Error(res.error || 'UNKNOWN')
  }
}

//Update wallet name &/or description
export const updateWallet = async (publicKeyHash, name, description = null) => {
  let res = await call("POST", '/client/wallet/update', { publicKeyHash, name, description })
  if (res.status === 'SUCCESS') {
    return res
  } else {
    throw new Error(res.error || 'UNKNOWN')
  }
}

//Update Bank ID i.e. Iban & BIC
export const updateIdbank = async (bank, iban, bic, previousIban) => {
  let res = await call("POST", '/client/idbank/update', { bank, iban, bic, previousIban })
  if (res.status === 'SUCCESS') {
    return res
  } else {
    throw new Error(res.error || 'UNKNOWN')
  }
}

//Delete IBAN reference
export const deleteIdbank = async (iban) => {
  let res = await call("POST", '/client/idbank/delete', { iban })
  if (res.status === 'SUCCESS') {
    return res
  } else {
    throw new Error(res.error || 'UNKNOWN')
  }
}

// GET all client accounts
export const getAccounts = async () => {
  return call("GET", `/client/accounts`)
}

//Create a new user
export const createUser = async ({ firstname, lastname, email, inwebo, admin }) => {
  return call("POST", '/client/account', { firstname, lastname, email, inwebo, admin })
}

//Delete user
export const rmUser = async (email) => {
  return call("POST", '/client/account/delete', { email })
}

//Reactivate user
export const reactivateUser = async (email) => {
  return call("POST", '/client/account/reactivate', { email })
}


