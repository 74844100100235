import React, { Component } from "react";
import {
  Row,
  Col,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { Line } from "rc-progress";
import { isAddress } from "web3-validator";

const IBAN = require("iban");
const BIC = require("bic");

class TxInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      bic: null,
      tezosPkh: null,
      ethPkh: null,
      iban: null,
      error: null,
    };
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  validateTxInfo = () => {
    if (!IBAN.isValid(this.state.iban || this.props.txinfo.iban))
      this.setState({ error: "IBAN format not valid" });
    else if (!BIC.isValid(this.state.bic || this.props.txinfo.bic))
      this.setState({ error: "BIC format not valid" });
    else if (this.state.ethPkh && this.state.ethPkh.length !== 42)
      this.setState({ error: "Ethereum address format invalid" });
    else {
      this.setState({ error: null });
      this.props.submitTxinfo({
        iban: this.state.iban || this.props.txinfo.iban,
        bic: this.state.bic || this.props.txinfo.bic,
        tezosPkh: this.state.tezosPkh || this.props.txinfo.tezosPkh,
        ethPkh: this.state.ethPkh || this.props.txinfo.ethPkh,
      });
    }
  };

  render() {
    return (
      <CardBody>
        <div>
          <h5>4. Transactional information</h5>
          <Line percent={60} strokeWidth={2} strokeColor="#2eecc7" />
          <Row className="mt-4">
            <Col className="col-xl-1 col-md-2">
              <h1 className="m-0">
                <i className="mdi mdi-currency-eur text-primary text-right"></i>
              </h1>
            </Col>
            <Col className="col-10 align-self-end">
              <p>
                Please provide us with an IBAN that you will use to buy and sell
                EUROP with €
              </p>
            </Col>
          </Row>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>IBAN</strong>{" "}
              <small>(International Bank Account Number)</small>{" "}
              <span className="text-danger">*</span>
            </p>
            <InputGroup>
              <Input
                name="iban"
                className="form-control"
                placeholder="IBAN"
                type="text"
                value={this.state.iban || this.props.txinfo.iban}
                onChange={(e) => this.setState({ iban: e.target.value })}
              />
              <InputGroupAddon addonType="append">
                {IBAN.isValid(this.state.iban || this.props.txinfo.iban) ? (
                  <h4 className="ml-2 text-success">
                    <b className="bx bx-check-shield"></b>
                  </h4>
                ) : (
                  <h4 className="ml-2 text-danger">
                    <b className="bx bx-shield-x"></b>
                  </h4>
                )}
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>BIC</strong>
              <small>(Bank Identifier Code)</small>{" "}
              <span className="text-danger">*</span>
            </p>
            <InputGroup>
              <Input
                name="bic"
                className="form-control"
                placeholder="BIC"
                type="text"
                value={this.state.bic || this.props.txinfo.bic}
                onChange={(e) => this.setState({ bic: e.target.value })}
                required
              />
              <InputGroupAddon addonType="append">
                {BIC.isValid(this.state.bic || this.props.txinfo.bic) ? (
                  <h4 className="ml-2 text-success">
                    <b className="bx bx-check-shield"></b>
                  </h4>
                ) : (
                  <h4 className="ml-2 text-danger">
                    <b className="bx bx-shield-x"></b>
                  </h4>
                )}
              </InputGroupAddon>
            </InputGroup>
          </div>
          <p>
            <span className="text-danger">*</span>{" "}
            <small>All mandatory fields</small>
          </p>
          {this.state.error && (
            <p className="my-1 text-danger">
              <strong>{this.state.error}</strong>
            </p>
          )}
          <hr />
          <Row>
            <Col className="col-1">
              <h1 className="m-0">
                <i className="mdi mdi-wallet text-primary text-right"></i>
              </h1>
            </Col>
            <Col className="col-10 align-self-end">
              <p>
                Please provide an Ethereum/Polygon address on which you would
                like to receive EUROP
              </p>
            </Col>
          </Row>
          <div className="form-group">
            <p className="mt-2 mb-1">
              <strong>Ethereum / Polygon</strong>
            </p>
            <InputGroup>
              <Input
                name="ethPkh"
                className="form-control"
                placeholder="Eth address"
                type="text"
                value={this.state.ethPkh || this.props.txinfo.ethPkh}
                onChange={(e) => this.setState({ ethPkh: e.target.value })}
              />
              {(this.state.ethPkh || this.props.txinfo.ethPkh) && (
                <InputGroupAddon addonType="append">
                  {isAddress(this.state.ethPkh || this.props.txinfo.ethPkh) ? (
                    <h4 className="ml-2 text-success">
                      <b className="bx bx-check-shield"></b>
                    </h4>
                  ) : (
                    <h4 className="ml-2 text-danger">
                      <b className="bx bx-shield-x"></b>
                    </h4>
                  )}
                </InputGroupAddon>
              )}
            </InputGroup>
          </div>
          <p>
            <small>
              You can add / edit your addresses anytime once your account has
              been validated
            </small>
          </p>
          <Row className="mt-3">
            <Col className="col-4">
              <div
                className="btn btn-secondary btn-block waves-effect waves-light"
                onClick={() => this.props.handleChangeView("owners")}
              >
                {"<"} Back
              </div>
            </Col>
            <Col className="col-8">
              <div
                className="btn btn-primary btn-block waves-effect waves-light"
                onClick={this.validateTxInfo}
              >
                Validate transactional info
              </div>
            </Col>
          </Row>
        </div>
      </CardBody>
    );
  }
}

export default TxInfo;
