import React, { Component } from "react";

import { Modal, Row, Col, FormGroup, Label } from "reactstrap";

class CredentialsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copied: null
        };
    }

    copyToken = () => {
        navigator.clipboard.writeText(this.props.apicredentials.token);
        this.setState({ copied: 'token' });
        setTimeout(
          function () {
            this.setState({ copied: null });
          }
            .bind(this),
          2000
        );
    }

    copySecret = () => {
        navigator.clipboard.writeText(this.props.apicredentials.secret);
        this.setState({ copied: 'secret' });
        setTimeout(
          function () {
            this.setState({ copied: null });
          }
            .bind(this),
          2000
        );
    }


  render() {
      return (
        <React.Fragment>
          <Modal
            size="md"
            isOpen={this.props.credentialsModal}
            centered={true}
            backdrop="static"
            wrapClassName="modal-balance"
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="myLargeModalLabel"
              >Client credentials</h5>
            </div>
            <div className="modal-body">
              {this.props.error && <p className="text-danger">{this.props.error}</p>}
              {this.props.success && <p className="text-success">{this.props.success}</p>}
              <FormGroup>
                    <Label>X-Access-Token</Label>

                    <Row>
                        <Col sm="12">
                            <p className="text-muted mb-2 text-break">
                                {this.props.apicredentials.token}
                                {this.state.copied !== 'token' ?
                                      this.props.apicredentials.token &&
                                      <span className="badge badge-primary clickable ml-2" onClick={() => { this.copyToken() }}>copy</span>
                                :
                                      <span className="badge text-success pointer ml-2">copied!</span>
                                }
                            </p>
                        </Col>
                    </Row>
                </FormGroup>

                <FormGroup>
                    <Label>Secret</Label>

                    <Row>
                        <Col sm="12">
                            <p className="text-muted mb-2 text-break">
                                {this.props.apicredentials.secret}
                                {this.state.copied !== 'secret' ?
                                      this.props.apicredentials.secret &&
                                      <span className="badge badge-primary clickable ml-2" onClick={() => { this.copySecret() }}>copy</span>
                                :
                                      <span className="badge text-success pointer ml-2">copied!</span>
                                }
                            </p>
                        </Col>
                    </Row>
                </FormGroup>
                <Row className="mt-3">
                    <Col sm="4">
                        <button className="btn btn-dark btn-block waves-effect waves-light" type="submit" onClick={() => this.props.toggleCredentialsModal()}>Close</button>
                    </Col>
                </Row>
            </div>
          </Modal>
        </React.Fragment>
      )}
}

export default CredentialsModal