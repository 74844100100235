import React, { Component } from 'react';

class FileManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            fileData: this.props.fileData.fileData,
            fileExtension: this.props.fileData.fileExtension,
            fileName: this.props.fileData.fileName,
        }
        this.hiddenFileInput = React.createRef();
    }

    handleFileUpload = () => {
        this.hiddenFileInput.current.click()
    }

    onFileFileHandler = async (event) => {
        const reader = new FileReader()
        this.setState({ processing: true, error: null })
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg', 'pdf'].includes(extension)) {
            this.setState({ processing: false, error: 'Invalid file. Only image or PDF.' })
        } else if (event.target.files[0].size > 12000000) {
            this.setState({ processing: false, error: 'File exceeds 10Mo' })
        } else {
            this.setState({ fileExtension: extension, fileName: event.target.files[0].name })
            reader.onloadend = () => this.processFile(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    processFile = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        await this.setState({ processing: false, fileData: base64data })
        this.props.cb(base64data, this.state.fileExtension, this.state.fileName)
    }

    render() {
        return (
            <>
                <input className="d-none" type="file" ref={this.hiddenFileInput}
                    accept="image/x-png,image/jpeg,application/pdf"
                    onChange={(e) => this.onFileFileHandler(e)} />
                {this.state.fileData &&
                    <div className="m-2">
                        {['jpg', 'png', 'jpeg'].includes(this.state.fileExtension) &&
                            <img style={{ display: "block", maxWidth: "200px", margin: "0 auto" }}
                                src={`data:image/${this.state.fileExtension};base64,${this.state.fileData}`}
                                alt="logo" />
                        }
                        {this.state.fileExtension === "pdf" &&
                            <div className="text-center" style={{ fontSize: "48px" }}><i className="mdi mdi-file-pdf-box text-right"></i></div>
                        }
                        <p onClick={this.handleFileUpload} className="mt-0 pointer text-center"><small><strong>{this.state.fileName}</strong> (Click to edit / change)</small></p>
                    </div>
                }
                {this.state.processing ?
                    <p>Processing... Please wait</p>
                    :
                    !this.state.fileData &&
                    <div className="btn-sm btn-info btn-block waves-effect waves-light" style={{ width: "fit-content" }} onClick={this.handleFileUpload}>
                        Click to select
                    </div>
                }
                {this.state.error && <p className="my-1 text-danger">
                        <strong>{this.state.error}</strong>
                    </p>}
            </>
        );
    }
}

export default FileManager;