import React, { Component } from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  CardFooter,
} from "reactstrap";
import Loader from "react-loader-spinner";
import HCaptcha from "@hcaptcha/react-hcaptcha";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginInwebo, apiError } from "../../store/actions";

import { RECAPTCHA_KEY } from "../../constants";

// import images
import TRUSTBUILDER from "../../assets/images/inwebo.png";
import BACK from "../../assets/images/back.jpg";

import Copyright from "../../components/Common/Copyright";

class TrustBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleChange = (value) => {
    this.setState({ value });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
  };

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.apiError("");
    this.setState({ loading: true });
    this.props.loginInwebo(values, this.props.history, this.state.value);
  }

  componentDidMount() {
    this.props.apiError("");
  }

  render() {
    const error = this.props.error
      ? JSON.parse(this.props.error.message)
      : null;
    return (
      <React.Fragment>
        <div
          className="account-pages pt-sm-5 bg-welcome"
          style={{ backgroundImage: `url('${BACK}')` }}
        >
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="wcard-schuman">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4"></div>
                    </Col>
                    <Col className="col-5 align-self-end"></Col>
                  </Row>
                </div>
                <Card className="overflow-hidden card-welcome">
                  <CardBody className="pt-4">
                    <div className="p-2">
                      <h5 className="mb-2">Authentication</h5>
                      <hr />
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {error && <Alert color="danger">{error.err}</Alert>}
                        <div className="form-group">
                          <AvField
                            name="email"
                            label="Email"
                            value=""
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>
                        <p className="text-muted font-size-12">
                          Authentication powered by
                          <br />
                          <img
                            src={TRUSTBUILDER}
                            height={24}
                            className="ml-1 mb-1"
                            alt="inWebo"
                          />
                        </p>
                        <HCaptcha
                          sitekey={RECAPTCHA_KEY}
                          onVerify={this.handleChange}
                        />
                        {this.state.loading && !error ? (
                          <React.Fragment>
                            <Loader
                              type="Puff"
                              color="#50a5f1"
                              height={50}
                              width={50}
                            />
                            <p className="mt-2 text-info">
                              Notification sent onto your TrustBuilder Authenticator
                              application.{" "}
                              <strong>
                                Set your pin code to unlock access...
                              </strong>
                            </p>
                          </React.Fragment>
                        ) : (
                          !error && (
                            <div className="mt-3 text-center">
                              <button
                                className="btn btn-primary btn-block waves-effect waves-light"
                                style={{ width: "fit-content" }}
                                type="submit"
                              >
                                Sign with TrustBuilder Authenticator
                              </button>
                            </div>
                          )
                        )}
                      </AvForm>
                    </div>
                  </CardBody>
                  <CardFooter style={{backgroundColor: "#2eecc7"}}>
                    <p className="text-center m-2">
                      Don't have an account yet?{" "}
                      <Link to="/register" className="text-primary">
                        <strong>Register you business now</strong>
                      </Link>
                    </p>
                  </CardFooter>
                </Card>
                <div className="mt-5 text-center">
                  <Copyright />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginInwebo, apiError })(TrustBuilder)
);
