import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Input, Label, Button, Nav, TabContent, TabPane, InputGroup, InputGroupAddon } from "reactstrap";
import { clientProfile, updateProfile } from '../../helpers/api';
import Loader from 'react-loader-spinner';

const nameLimit = 40;
const regEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: null,
            email: null,
            validEmail: true,
            nameCharLeft: nameLimit,
            pending: false,
            error: null,
            activeTab: '1',
            vatNumber: null,
            logo: null,
            processing: false,
            fileSize: 0,
            fileExtension: 'jpg',
            fileData: null,

        }
        this.hiddenFileInput = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange() {
        this.setState({ checked: !this.state.checked });
    }

    handleUpload = () => {
        this.hiddenFileInput.current.click()
    }

    onFileHandler = async (event) => {
        const reader = new FileReader()
        this.setState({ processing: true, error: null, success: false, info: null })
        const extension = event.target.files[0] && event.target.files[0].name.split('.').pop().toLowerCase()
        if (!extension || !['jpg', 'png', 'jpeg'].includes(extension)) {
            this.setState({ processing: false, error: 'Invalid image file' })
        } else if (event.target.files[0].size > 12000000) {
            this.setState({ processing: false, error: 'Image exceeding 10Mo' })
        } else {
            this.setState({ fileSize: event.target.files[0].size, fileExtension: extension })
            reader.onloadend = () => this.processFile(reader)
            reader.readAsArrayBuffer(event.target.files[0])
        }
    }

    processFile = async (reader) => {
        const buffer = Buffer.from(reader.result)
        const base64data = buffer.toString('base64')
        this.setState({ processing: false, fileData: base64data })
    }

    updateName = (event) => {
        this.setState({ nameCharLeft: nameLimit - event.target.value.length, name: event.target.value });
    }

    updateEmail = (event) => {
        this.setState({ email: event.target.value, validEmail: regEmail.test(event.target.value) });
    }

    updateVatNumber = (event) => {
        this.setState({ vatNumber: event.target.value });
    }

    updtProfile = () => {
        if (this.state.name.length < 4)
            this.setState({ error: 'Invalid company name!', pending: false });
        else if (!this.state.validEmail)
            this.setState({ error: 'Invalid email!', pending: false });
        else {
            this.setState({ pending: true, error: null });
            this._asyncRequest = updateProfile(this.state.name, this.state.email, this.state.fileData, this.state.vatNumber).then(
                res => {
                    if (res.status === 'SUCCESS') {
                        window.location = '/';
                    } else {
                        this.setState({ error: res.error, pending: false });
                    }
                }
            ).catch(error => {
                this.setState({ error: error.toString(), pending: false });
            });
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true })
        const res = await clientProfile()
        this.setState({
            name: res.data.client.name, email: res.data.client.email, vatNumber: res.data.client.vatNumber, 
            logo: res.data.client.logo, loading: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {this.state.loading ?
                            <Loader type="Puff" color="#545454" height={50} width={50} />
                            :
                            <Row>
                                <Col lg="12">
                                    <Card>
                                        <CardBody>
                                            <h4 className="card-title mb-4"><i className="bx bx-building"></i> Update company profile information</h4>
                                            <div className="crypto-buy-sell-nav">
                                                <Nav tabs className="nav-tabs-custom" role="tablist">
                                                </Nav>

                                                <TabContent activeTab={this.state.activeTab} className="crypto-buy-sell-nav-content p-4">
                                                    <TabPane tabId="1" id="create">
                                                        {this.state.error && <p className="text-danger">{this.state.error}</p>}

                                                        <FormGroup>
                                                            <Label>Client logo <small>*optional (only jpg or png)</small></Label>
                                                            {this.state.logo && !this.state.fileData && <img src={`https://premiere.infura-ipfs.io/ipfs/${this.state.logo}`} alt='Company logo' className="my-2 d-block" style={{ maxWidth: '200px' }} />}
                                                            <Row>
                                                                <Col sm="8">
                                                                    <input className="d-none" type="file" ref={this.hiddenFileInput}
                                                                        accept="image/x-png,image/gif,image/jpeg"
                                                                        onChange={(e) => this.onFileHandler(e)} />
                                                                    {this.state.fileData &&
                                                                        <div className="m-2">
                                                                            <img style={{ display: "block", maxWidth: "200px" }}
                                                                                src={`data:image/${this.state.fileExtension};base64,${this.state.fileData}`}
                                                                                alt="logo" />
                                                                        </div>
                                                                    }
                                                                    {this.state.processing ?
                                                                        <p>Processing... Please wait</p>
                                                                        :
                                                                        <Button type="button" color="light" onClick={this.handleUpload}>Select logo</Button>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label>Client name</Label>
                                                            <Row>
                                                                <Col sm="8">
                                                                    <InputGroup className="mb-2">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <span className="input-group-text">{this.state.nameCharLeft} char. left</span>
                                                                        </InputGroupAddon>
                                                                        <Input type="text" className="form-control" value={this.state.name} maxLength={nameLimit} onChange={this.updateName} autoComplete="off" />
                                                                        <InputGroupAddon addonType="append">
                                                                            {this.state.name && this.state.name.length > 3 ?
                                                                                <h4 className="ml-2 text-success"><b className="bx bx-message-alt-check"></b></h4>
                                                                                :
                                                                                <h4 className="ml-2 text-danger"><b className="bx bx-message-alt-x"></b></h4>
                                                                            }
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label>Client email</Label>
                                                            <Row>
                                                                <Col sm="8">
                                                                    <InputGroup className="mb-2">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <span className="input-group-text">@</span>
                                                                        </InputGroupAddon>
                                                                        <Input type="email" className="form-control" value={this.state.email} onChange={this.updateEmail} autoComplete="off" />
                                                                        <InputGroupAddon addonType="append">
                                                                            {this.state.validEmail ?
                                                                                <h4 className="ml-2 text-success"><b className="bx bx-message-alt-check"></b></h4>
                                                                                :
                                                                                <h4 className="ml-2 text-danger"><b className="bx bx-message-alt-x"></b></h4>
                                                                            }
                                                                        </InputGroupAddon>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Label>VAT number <small>*optional</small></Label>
                                                            <Row>
                                                                <Col sm="12">
                                                                    <InputGroup className="mb-2">
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <span className="input-group-text">VAT</span>
                                                                        </InputGroupAddon>
                                                                        <Input type="text" className="form-control" value={this.state.vatNumber} onChange={this.updateVatNumber} placeholder='VAT number' />
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <div className="mt-2">
                                                                {this.state.pending ?
                                                                    <React.Fragment>
                                                                        <Loader type="Puff" color="#50a5f1" height={20} width={20} />
                                                                        <p className="mt-2 text-info">
                                                                            Updating company profile. <strong>Please wait few seconds...</strong>
                                                                        </p>
                                                                    </React.Fragment>
                                                                    :
                                                                    <Button type="button" color="light" onClick={this.updtProfile}>Update company profile</Button>
                                                                }
                                                            </div>
                                                        </FormGroup>
                                                    </TabPane>
                                                </TabContent>
                                            </div>

                                        </CardBody>

                                    </Card>
                                </Col>
                            </Row>}
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EditProfile;