const FINANCIAL_ADMIN = [
  'owner:setMinter',
  'owner:setReserver',
  'owner:setAdministrator',
]

const isFinancialAllowedForOperator = (data) => {
  let { contract, op } = data
  return !FINANCIAL_ADMIN.includes(`${contract}:${op}`)
}

const rules = {
  admin: {
    static: [
      "admin:access",
      'operator:financial',
      'operator:wallet',
      "admin:financial",
      "reader:all",
    ],
    dynamic: {
    }
  },

  operator: {
    static: [
      'operator:financial',
      'operator:wallet',
      "reader:all",
    ],
    dynamic: {
      "admin:financial": isFinancialAllowedForOperator
    }
  },

  reader: {
    static: ['reader:all']
  },

  client: {
    static: ['client:requests']
  },

  client_admin: {
    static: ['client:admin', 'client:requests']
  }
};

export default rules;